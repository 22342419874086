import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Pagination from '@mui/material/Pagination'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import { getChannelEvents, getProjectList, getQueryParamsObj } from '@/api'
import classNames from 'classnames'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { FormControl, MenuItem, Select } from '@mui/material'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(238,238,238,0.3)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  turnPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationBox: {
    '&>ul>li>.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#c4c1c1',
      color: '#333',
    },
    '&>ul>li>button': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 18,
        height: 26,
        minWidth: 26,
      },
    },
    '&>ul>li>button>svg': {
      fontSize: 45,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 35,
      },
    },
    '&>ul>li>div': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 26,
        height: 30,
        minWidth: 30,
      },
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '30%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '80%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '75%',
    },
  },
  selectBigBox: {
    width: '24%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '24%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  selectBigBoxTwo: {
    width: '40%',
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    '@media (max-width: 1280px)': {
      width: '12vh',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 3.2vh 6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  iptModalDelete: {
    '&>div>input': {
      width: '88% !important',
    },
  },
  selectBox: {
    width: '75%',
    height: '100%',
    position: 'relative',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  tableBox: {
    height: 'calc( 93% - 48px - 10px )',
    width: '100%',
    overflowY: 'auto',
    '@media (max-width: 1280px)': {
      height: 'calc( 70% - 30px)',
      '&>table': {
        minWidth: 1000,
      },
    },
  },
  btn: {
    marginLeft: 15,
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  dateBox: {
    height: '100%',
    width: '85%',
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      height: '100%',
      padding: 0,
      overflow: 'hidden',
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '&>input': {
            padding: '0 10px',
          },
          '&>fieldset': {
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  thBox: {
    lineHeight: '34px',
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  iconBox: {
    width: '3vh',
    height: '3vh',
    position: 'absolute',
    right: 4,
    top: 'calc( 50% - 1.5vh )',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface ChannelEventsBoxProps {
  divWidth: any
  urlName: any
}

const ChannelEventsBox = (props: ChannelEventsBoxProps) => {
  const { divWidth } = props
  const { classes } = useStyles()
  const [arrList, setArrList] = useState<any>('')
  const [selectValue, setSelectValue] = useState('')
  const [dateStartValue, setDateStartValue] = useState<any>('')
  const [dateEndValue, setDateEndValue] = useState<any>('')
  const [plateString, setPlateString] = useState<any>('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const navigate = useNavigate()
  const dateObj = getQueryParamsObj(window.location?.search)

  const headerArr = [
    {
      key: 0,
      name: '小区名',
      width: '9%',
    },
    {
      key: 1,
      name: '车牌号',
      width: '12%',
    },
    {
      key: 2,
      name: '事件时间',
      width: '15%',
    },
    {
      key: 3,
      name: '通道名称',
      width: '9%',
    },
    {
      key: 4,
      name: '事件名称',
      width: '12%',
    },
    {
      key: 5,
      name: '进出方向',
      width: '9%',
    },
    {
      key: 6,
      name: '车辆类型',
      width: '9%',
    },
    {
      key: 7,
      name: '车型类型',
      width: '9%',
    },
    {
      key: 8,
      name: '备注',
      width: '16%',
    },
  ]

  const getChannelEventsArr = (
    projectId: any,
    plateNumber: any,
    startTime: any,
    endTime: any,
    page: any,
    size: any,
  ) => {
    getChannelEvents(projectId, plateNumber, startTime, endTime, page, size)
      .then((res) => {
        setArrList(res)
      })
      .catch(console.error)
  }

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getList = (dateStart?: any, dateEnd?: any) => {
    const tableBox = document.getElementById('tableBox')
    if (tableBox?.clientHeight) {
      divWidth <= 1280
        ? getChannelEventsArr(
            selectValue || dateObj?.id,
            plateString?.replace(/\s+/g, '') || decodeURI(dateObj?.result),
            dateStartValue || dateStart,
            dateEndValue || dateEnd,
            page - 1,
            Math.trunc(tableBox?.clientHeight / 35) - 1,
          )
        : getChannelEventsArr(
            selectValue || dateObj?.id,
            plateString?.replace(/\s+/g, '') || decodeURI(dateObj?.result),
            dateStartValue || dateStart,
            dateEndValue || dateEnd,
            page - 1,
            Math.trunc(tableBox?.clientHeight / 54) - 1,
          )
    } else {
      getChannelEventsArr(
        selectValue || dateObj?.id,
        plateString?.replace(/\s+/g, '') || decodeURI(dateObj?.result),
        dateStartValue || dateStart,
        dateEndValue || dateEnd,
        page - 1,
        12,
      )
    }
  }

  useEffect(() => {
    if (arrList?.data?.length > 0) {
      getList()
    }
  }, [page])

  useEffect(() => {
    getParkingLotArr()
    if (dateObj?.date) {
      getList(dateObj?.date?.split('~')[0] || '', dateObj?.date?.split('~')[1] || '')
    }
  }, [])

  useEffect(() => {
    setArrList([])
    if (dateObj?.date) {
      setDateStartValue(dateObj?.date?.split('~')[0] || '')
      setDateEndValue(dateObj?.date?.split('~')[1] || '')
    }
    if (dateObj?.result) {
      setPlateString(decodeURI(dateObj?.result))
    }
    if (dateObj?.id) {
      setSelectValue(dateObj?.id || '')
    }
  }, [dateObj?.id, dateObj?.date, dateObj?.result])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>小区：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={arrParkingLotList?.length > 0 ? selectValue : ''}
                  onChange={(event: any) => {
                    setSelectValue(event.target.value)
                  }}
                >
                  {arrParkingLotList?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {`${item.name}-${item.id}`}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classNames([classes.selectBigBox, classes.selectBigBoxTwo])}>
            <div className={classes.titleText}>时间：</div>
            <div className={classes.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    ampm={false}
                    disableFuture
                    format="YYYY/MM/DD HH:mm:ss"
                    views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                    value={dayjs(dateStartValue)}
                    onChange={(e: any) => {
                      setDateStartValue(dayjs(e).format('YYYY-MM-DDTHH:mm:ss'))
                    }}
                  />
                </DemoContainer>
                {divWidth > 1280 && (
                  <>
                    <span>~</span>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        ampm={false}
                        disableFuture
                        format="YYYY/MM/DD HH:mm:ss"
                        views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                        value={dayjs(dateEndValue)}
                        onChange={(e: any) => {
                          setDateEndValue(dayjs(e).format('YYYY-MM-DDTHH:mm:ss'))
                        }}
                      />
                    </DemoContainer>
                  </>
                )}
              </LocalizationProvider>
            </div>
          </div>
          {divWidth <= 1280 && (
            <div className={classNames([classes.selectBigBox, classes.selectBigBoxTwo])}>
              <div className={classes.titleText} />
              <div className={classes.dateBox}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateTimePicker']}>
                    <DateTimePicker
                      ampm={false}
                      disableFuture
                      format="YYYY/MM/DD HH:mm:ss"
                      views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                      value={dayjs(dateEndValue)}
                      onChange={(e: any) => {
                        setDateEndValue(dayjs(e).format('YYYY-MM-DDTHH:mm:ss'))
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div style={{ width: '12vh' }} className={classes.titleText}>
              车牌号：
            </div>
            <div className={classes.selectBox}>
              <TextField
                className={classNames([classes.iptModal, classes.iptModalDelete])}
                value={plateString}
                onChange={(event: any) => {
                  setPlateString(event.target.value)
                }}
                id="outlined-basic"
                variant="outlined"
              />
              {plateString !== '' && (
                <HighlightOffIcon
                  className={classes.iconBox}
                  onClick={() => {
                    setPlateString('')
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              getList()
              page !== 1 && setPage(1)
              let newStr = `?id=${dateObj?.id}&date=${dateStartValue}~${dateEndValue}`
              if (plateString?.replace(/\s+/g, '')) {
                newStr += `&result=${plateString?.replace(/\s+/g, '')}`
              }
              navigate(newStr)
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    console.log(11)*/}
          {/*  }}*/}
          {/*  variant="contained"*/}
          {/*  className={classes.btn}*/}
          {/*>*/}
          {/*  导出*/}
          {/*</Button>*/}
        </div>
      </div>
      <div id="tableBox" className={classes.tableBox}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {headerArr?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {arrList?.data?.map((item: any, i: number) => {
              return (
                <StyledTableRow key={i}>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.projectName}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.plateNumber}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.eventTime !== null ? moment.utc(item?.eventTime).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.gateName}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.eventGame}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.ioState}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.carTypeName}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.carModelName}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.remark}</div>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <div className={classes.turnPage}>
        <Pagination
          className={classes.paginationBox}
          boundaryCount={1}
          page={page}
          onChange={(_e, num: number) => {
            setPage(num)
          }}
          count={arrList?.pages || 0}
          shape="rounded"
        />
      </div>
    </div>
  )
}

export default ChannelEventsBox
