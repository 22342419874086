import React from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  body: {
    width: '18%',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  btnBox: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      background: '#232425',
      color: '#fff',
    },
  },
  btnBoxBg: {
    background: '#232425',
    color: '#fff',
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    lineHeight: '34px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thFont: {
    fontWeight: 600,
  },
}))

interface UnbilledDataCollapseBoxProps {
  openBoo: boolean
  allArr: any
}

const UnbilledDataCollapseBox = (props: UnbilledDataCollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, allArr } = props

  return (
    <Collapse in={openBoo} timeout="auto" unmountOnExit>
      <Table stickyHeader aria-label="customized table">
        <TableBody>
          {allArr?.map((item: any, i: any) => {
            return (
              <StyledTableRow key={i}>
                <StyledTableCell style={{ width: '10%' }} align="right" />
                <StyledTableCell align="right">
                  <div className={classes.thBox1}>{item?.charge_name}</div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className={classes.thBox1}>{item?.current_month_income?.toFixed(2)}</div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className={classes.thBox1}>{item?.current_month_prepay?.toFixed(2)}</div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className={classes.thBox1}>{item?.current_month_apportion_prepayment?.toFixed(2)}</div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <div className={classes.thBox1}>{item?.remain_prepayment?.toFixed(2)}</div>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}

export default UnbilledDataCollapseBox
