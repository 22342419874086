import React, { useEffect, useState } from 'react'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import ControlBox from '@/components/adminBaseMap/controlsBox'
import MapBoxPairs from '@/components/baseMap/mapBoxPairs'
import { getProjectObj, getQueryParams } from '@/api'
import { useParams } from 'react-router-dom'

interface AdminBaseMapProps {
  configJson: any
}

const AdminBaseMap = (props: AdminBaseMapProps) => {
  const { configJson } = props
  const { id } = useParams()
  const [mapBox, setMapBox] = useState<any>('')
  const [projectsObj, setProjectsObj] = useState('')
  const [allArr, setAllArr] = useState<any>({})

  const arrObj = () => {
    const mapBoxGeoJSONArr: any = []
    const mapBoxImageArr: any = []
    const mapBoxIconArr: any = []
    const deckGlGeoJSONArr: any = []
    const deckGlImageArr: any = []
    const deckGlIconArr: any = []
    const deckGlScenegraphArr: any = []
    // const deckGlHeatMapArr: any = []
    const mapBoxAllArr: any[] = []
    const deckAllArr: any[] = []
    getQueryParams(window.location?.search)?.source?.map((item: any) => {
      // @ts-ignore
      if (configJson[id]) {
        // @ts-ignore
        switch (configJson[id][item[0]]?.renderType) {
          case 'mapBoxGeoJSON':
            mapBoxGeoJSONArr.push(item)
            mapBoxAllArr.push(item.toString())
            deckAllArr.push(item.toString())
            break
          case 'mapBoxImage':
            mapBoxImageArr.push(item)
            mapBoxAllArr.push(item.toString())
            break
          case 'mapboxIcon':
            mapBoxIconArr.push(item)
            mapBoxAllArr.push(item.toString())
            break
          case 'deckGlGeoJSON':
            deckGlGeoJSONArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlImage':
            deckGlImageArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlIcon':
            deckGlIconArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlScenegraph':
            deckGlScenegraphArr.push(item)
            deckAllArr.push(item.toString())
            break
          // case 'deckGlHeatMap':
          //   deckGlHeatMapArr.push(item)
          //   deckAllArr.push(item.toString())
          //   break
          default:
            break
        }
      }
    })
    setAllArr({
      mapBoxGeoJSONArr,
      mapBoxImageArr,
      mapBoxIconArr,
      deckGlGeoJSONArr,
      deckGlImageArr,
      deckGlIconArr,
      deckGlScenegraphArr,
      // deckGlHeatMapArr,
      mapBoxAllArr,
      deckAllArr,
    })
  }
  const getMapBox = (map: any) => {
    setMapBox(map)
  }

  useEffect(() => {
    if (id) {
      getProjectObj(id)
        .then((res: any) => {
          document.title = res?.name
          setProjectsObj(res)
        })
        .catch(console.error)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      arrObj()
    }
  }, [id])

  return (
    <>
      <MapBoxPairs id={id} projectsObj={projectsObj} allArr={allArr} getMapBox={getMapBox} configJson={configJson} />
      <ControlBox id={id} mapBox={mapBox} configJson={configJson} />
    </>
  )
}

export default AdminBaseMap
