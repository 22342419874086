import React from 'react'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import NavigationImg from '@/image/navigationImg.png'
import MenuIcon from '@mui/icons-material/Menu'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '6%',
    background: '#15283c',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '3vh',
    position: 'relative',
    backgroundImage: `url(${NavigationImg})`,
    '&>div': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      width: '75%',
    },
  },
  rootBg: {
    boxShadow: '0px 4px 13px -5px #000',
    zIndex: 1,
  },
  menuIcon: {
    position: 'absolute',
    width: '4vh',
    height: '4vh',
    top: '1vh',
    zIndex: 2,
    left: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface HeaderBoxProps {
  headerName: any
  divWidth: any
  stretch: any
  getStretch: (boo: any) => void
}

const HeaderBox = (props: HeaderBoxProps) => {
  const { headerName, divWidth, getStretch, stretch } = props
  const { classes } = useStyles()

  return (
    <div className={classNames([classes.root, classes.rootBg])}>
      {divWidth > 1280 && (
        <MenuIcon
          onClick={() => {
            getStretch(!stretch)
          }}
          className={classes.menuIcon}
        />
      )}
      <div>{headerName}</div>
    </div>
  )
}

export default HeaderBox
