import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import { getDesc } from '@/api'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.5vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.6vh',
    lineHeight: 1.15,
    border: '1px solid rgba(0,0,0,0.6)',
    borderTop: 0,
    borderRight: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledLastTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.6vh',
    lineHeight: 1.15,
    border: '1px solid rgba(0,0,0,0.6)',
    borderTop: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledOneTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.6vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(0,0,0,0.6)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTwoTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.6vh',
    lineHeight: 1.15,
    border: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledThreeTableCell = withStyles(TableCell, (theme) => ({
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.5vh',
    lineHeight: 1.15,
    border: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  thBox: {
    lineHeight: '18px',
    minHeight: 18,
    '@media (max-width: 1280px)': {
      lineHeight: '12px',
      minHeight: 12,
    },
  },
  thBox2: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableBox: {
    width: 500,
  },
  widthOne: {
    width: 'calc( 100% / 9 )',
  },
  widthTwo: {
    width: 'calc( 100% / 9 * 3 )',
  },
  widthThree: {
    width: 'calc( 100% / 9 * 5 )',
  },
  widthFour: {
    width: 'calc( 100% / 9 * 6 )',
  },
  widthFive: {
    width: 'calc( 100% / 9 * 2 )',
  },
}))

interface OneTableBoxProps {
  arrList: any
}

const OneTableBox = (props: OneTableBoxProps) => {
  const { classes } = useStyles()
  const { arrList } = props
  const [newObj, setNewObj] = useState<any>('')
  const [incomeObj, setIncomeObj] = useState<any>({
    originDebitAmount: 0,
    debitAmount: 0,
    creditAmount: 0,
  })

  useEffect(() => {
    setIncomeObj({
      originDebitAmount: 0,
      debitAmount: 0,
      creditAmount: 0,
    })
    if (arrList !== '' && arrList?.name !== '四街坊') {
      let originDebitAmountNum: any = 0
      let debitAmountNum: any = 0
      let creditAmountNum: any = 0
      arrList?.income?.map((res: any) => {
        originDebitAmountNum += res?.originDebitAmount
        debitAmountNum += res?.debitAmount
      })
      arrList?.expenditure?.map((res: any) => {
        creditAmountNum += res?.creditAmount
      })
      setIncomeObj({
        originDebitAmount: originDebitAmountNum,
        debitAmount: debitAmountNum,
        creditAmount: creditAmountNum,
      })
      setNewObj(arrList)
    }
  }, [arrList])

  return (
    newObj && (
      <Table id="pdfTable" className={classes.tableBox} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={9} align="center">
              {newObj?.name}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledOneTableCell colSpan={9} align="center">
              {`${newObj?.year}年${newObj?.quarter}业委会公益性收支明细`}
            </StyledOneTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>项目</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>金额</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>业委会可用资金</div>
            </StyledLastTableCell>
          </StyledTableRow>

          {/*收入*/}
          {newObj?.income?.map((item: any, i: any) => {
            return (
              <StyledTableRow key={i}>
                <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
                  <div className={classes.thBox}>{item?.summary}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={3} align="right">
                  <div className={classes.thBox}>{item?.originDebitAmount?.toFixed(2)}</div>
                </StyledTableCell>
                <StyledLastTableCell className={classes.widthTwo} colSpan={3} align="right">
                  <div className={classes.thBox}>{item?.debitAmount?.toFixed(2)}</div>
                </StyledLastTableCell>
              </StyledTableRow>
            )
          })}
          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>{`${newObj?.quarter}收入合计`}</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="right">
              <div className={classes.thBox}>{incomeObj?.originDebitAmount?.toFixed(2)}</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthTwo} colSpan={3} align="right">
              <div className={classes.thBox}>{incomeObj?.debitAmount?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.widthOne} rowSpan={newObj?.expenditure?.length + 2} align="center">
              <div className={classes.thBox2}>
                <span>费</span>
                <span>用</span>
                <span>支</span>
                <span>出</span>
              </div>
            </StyledTableCell>
          </StyledTableRow>

          {/*支出*/}
          {newObj?.expenditure?.map((item: any, i: any) => {
            return (
              <StyledTableRow key={i}>
                <StyledTableCell className={classes.widthThree} colSpan={5} align="left">
                  <div className={classes.thBox}>{item?.summary}</div>
                </StyledTableCell>
                <StyledLastTableCell className={classes.widthTwo} colSpan={3} align="right">
                  <div className={classes.thBox}>{item?.creditAmount?.toFixed(2)}</div>
                </StyledLastTableCell>
              </StyledTableRow>
            )
          })}
          <StyledTableRow>
            <StyledTableCell className={classes.widthThree} colSpan={5} align="left">
              <div className={classes.thBox}>{`${newObj?.quarter}支出合计`}</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthTwo} colSpan={3} align="right">
              <div className={classes.thBox}>{incomeObj?.creditAmount?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>当期结余</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>{(incomeObj?.debitAmount - incomeObj?.creditAmount)?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>上期结存</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>{newObj?.lastQuarterAmount?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>期初调整</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>{newObj?.currentQuarterAdjustment?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell className={classes.widthTwo} colSpan={3} align="center">
              <div className={classes.thBox}>累计结余</div>
            </StyledTableCell>
            <StyledLastTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>{newObj?.currentQuarterAmount?.toFixed(2)}</div>
            </StyledLastTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledThreeTableCell className={classes.widthTwo} colSpan={9} align="left">
              <div dangerouslySetInnerHTML={{ __html: `备注：${getDesc(newObj?.remark)}` }} className={classes.thBox} />
            </StyledThreeTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTwoTableCell className={classes.widthTwo} colSpan={9} align="left">
              <div className={classes.thBox} />
            </StyledTwoTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledThreeTableCell className={classes.widthOne} align="left">
              <div className={classes.thBox} />
            </StyledThreeTableCell>
            <StyledThreeTableCell className={classes.widthFive} colSpan={2} align="left">
              <div className={classes.thBox}>业委会盖章</div>
            </StyledThreeTableCell>
            <StyledThreeTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>上海畅苑物业管理有限公司</div>
            </StyledThreeTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledThreeTableCell className={classes.widthOne} align="left">
              <div className={classes.thBox} />
            </StyledThreeTableCell>
            <StyledThreeTableCell className={classes.widthFive} colSpan={2} align="left">
              <div className={classes.thBox}>日期：</div>
            </StyledThreeTableCell>
            <StyledThreeTableCell className={classes.widthFour} colSpan={6} align="center">
              <div className={classes.thBox}>{`${moment.utc(new Date()).format('YYYY')}年${moment
                .utc(new Date())
                .format('MM')}月`}</div>
            </StyledThreeTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    )
  )
}

export default OneTableBox
