import React, { useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import LinkIcon from '@mui/icons-material/Link'
import RowBox from '@/components/baseMap/rowBox'

const useStyles = makeStyles()(() => ({
  btnBox: {
    position: 'absolute',
    zIndex: 1,
    top: 5,
    right: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    background: '#fff',
    color: '#000',
    padding: 5,
    minWidth: 'auto',
    marginBottom: 5,
    '&:hover': {
      background: '#fff',
      color: '#000',
    },
    '&>svg': {
      width: 50,
      height: 50,
    },
  },
  modalBox: {
    position: 'absolute',
    zIndex: 1,
    top: 5,
    right: 75,
    width: '15%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    '&>div:nth-of-type(2)': {
      textAlign: 'end',
    },
  },
}))

interface BaseMapBoxProps {
  urlSetting: any
  configJson: any
  urlSetArr: (obj: any) => void
}

const ModalBox = (props: BaseMapBoxProps) => {
  const { urlSetting, configJson, urlSetArr } = props
  const { classes } = useStyles()
  const [displayValue, setDisplayValue] = useState(false)

  return (
    <>
      <div className={classes.btnBox}>
        <Button
          onClick={() => {
            setDisplayValue(!displayValue)
          }}
          variant="contained"
          className={classes.btn}
        >
          <LinkIcon />
        </Button>
      </div>
      <div style={{ display: displayValue ? 'inline-block' : 'none' }} className={classes.modalBox}>
        <RowBox urlSetArr={urlSetArr} configJson={configJson} urlSetting={urlSetting} />
      </div>
    </>
  )
}

export default ModalBox
