import React, { useEffect, useRef, useState } from 'react'
import Map from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapBoxGeoJSONBox from 'src/components/baseMap/mapBoxGeoJSONBox'
import MapBoxImageBox from '@/components/baseMap/mapBoxImageBox'

interface MapProps {
  getMapBox?: (map: any) => void
  allArr: any
  configJson: any
  projectsObj: any
  id: any
}

function MapBoxPairs(props: MapProps) {
  const { getMapBox, allArr, configJson, projectsObj, id } = props
  const mapRef = useRef()
  const [mapBoxBoo, setMapBoxBoo] = useState<any>(false)
  const [viewStateObj, setViewStateObj] = useState<any>({
    longitude: 122,
    latitude: 31.2603,
    zoom: 16.5,
    pitch: 45,
  })

  const setLoad = () => {
    setMapBoxBoo(true)
    const map: any = mapRef.current
    map.setCenter([viewStateObj?.longitude, viewStateObj?.latitude])
    map.setZoom(viewStateObj?.zoom)
    map.setPitch(viewStateObj?.pitch)
    getMapBox?.(map)
  }

  useEffect(() => {
    if (projectsObj !== '') {
      if (projectsObj?.longitude && projectsObj?.latitude && projectsObj?.zoom && projectsObj?.pitch) {
        setViewStateObj({
          longitude: projectsObj?.longitude,
          latitude: projectsObj?.latitude,
          zoom: projectsObj?.zoom,
          pitch: projectsObj?.pitch,
        })
      }
    }
  }, [projectsObj])

  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoiZm94em9vbCIsImEiOiJjanJidmM1bXAwNmE0M3lwM214b3Q1bGxmIn0.vZ1amAfCBSYzN5W1uXn_lw"
      mapStyle="mapbox://styles/mapbox/streets-v12"
      initialViewState={{
        longitude: 122,
        latitude: 31.25952260789846,
        zoom: 15.5,
        pitch: 45,
      }}
      onLoad={setLoad}
      // @ts-ignore
      ref={mapRef}
    >
      {mapBoxBoo && <MapBoxImageBox id={id} arr={allArr} map={mapRef.current} configJson={configJson} />}
      {mapBoxBoo && <MapBoxGeoJSONBox id={id} configJson={configJson} arr={allArr} map={mapRef.current} />}
    </Map>
  )
}

export default MapBoxPairs
