import React, { useRef } from 'react'
import { makeStyles } from 'tss-react/mui'
import PlayerOne from '@/components/adminBox/surveillanceVideo/playerBox/platerOne'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    color: '#000',
    overflow: 'hidden',
  },
}))

interface SurveillanceVideoProps {
  uuid: any
}

const SurveillanceVideo = (props: SurveillanceVideoProps) => {
  const { uuid } = props
  const { classes } = useStyles()
  const videoRef = useRef<HTMLVideoElement>(null)

  return (
    <div className={classes.root}>
      <PlayerOne uuid={uuid} videoRef={videoRef} urlId={0} />
    </div>
  )
}

export default SurveillanceVideo
