import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import { exportExcel, getManagementFee, getProjectList, getQueryParamsObj, getRent, getShopManagementFee } from '@/api'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import { FormControl, MenuItem, Select } from '@mui/material'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '32px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '27%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '55%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '75%',
      width: '90%',
    },
    '@media (min-width: 1281px)': {
      justifyContent: 'space-between',
    },
  },
  selectBigBox: {
    width: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '30%',
    },
  },
  selectWidth: {
    width: '45%',
    '@media (max-width: 1280px)': {
      width: '100%',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
        paddingRight: '20px !important',
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  iptModalWidth: {
    width: '22%',
    '@media (max-width: 1280px)': {
      width: '35%',
      maxWidth: '22vh',
    },
  },
  tableBox: {
    overflow: 'auto',
    minWidth: 800,
  },
  selectBox: {
    width: '75%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  dateBox: {
    height: '100%',
    width: '75%',
    '&>div': {
      height: '100%',
      padding: 0,
      overflow: 'hidden',
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '&>input': {
            padding: '0 10px',
            fontSize: '1.8vh',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
          '&>div>button': {
            padding: 5,
          },
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  totalBox: {
    display: 'flex',
    width: '100%',
    background: '#fff',
    borderRadius: ' 0 0 5px 5px',
    color: '#000',
    minWidth: 800,
    '&>div': {
      width: '10%',
      padding: '0.5vh',
      fontSize: '2vh',
      lineHeight: 1.5,
      fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
      fontWeight: 600,
      textAlign: 'right',
      '@media (max-width: 1280px)': {
        fontSize: '1.6vh',
      },
    },
  },
  iphoneBox: {
    height: '93%',
    '@media (max-width: 1280px)': {
      height: '73%',
      overflow: 'auto',
    },
  },
}))

interface MonthlyReportTwoBoxProps {
  divWidth: any
  urlName: any
}

const MonthlyReportTwoBox = (props: MonthlyReportTwoBoxProps) => {
  const { urlName, divWidth } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [allArr, setAllArr] = useState<any>([])
  const [paddingBoo, setPaddingBoo] = useState(false)
  const [selectValue, setSelectValue] = useState('all')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [arrList, setArrList] = useState<any>([])
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>('')
  const [monthValue, setMonthValue] = useState<any>([1, 12])
  const [arrExportList, setArrExportList] = useState<any>({})
  const [totalObj, setTotalObj] = useState<any>({
    historicalArrears: 0,
    preYearArrears: 0,
    currentYearArrears: 0,
    currentMonthActual: 0,
    currentYearPrepaidActual: 0,
    nextYearPrepaidActual: 0,
    total: 0,
    preYearAllocCurrentMonth: 0,
    thisYearAllocCurrentMonth: 0,
  })

  const tableBigBox = document.getElementById('tableBigBox')
  const totalBox = document.getElementById('totalBox')
  const tableBox = document.getElementById('tableBox')
  const monthArr = [
    {
      name: '1月',
      value: 1,
    },
    {
      name: '2月',
      value: 2,
    },
    {
      name: '3月',
      value: 3,
    },
    {
      name: '4月',
      value: 4,
    },
    {
      name: '5月',
      value: 5,
    },
    {
      name: '6月',
      value: 6,
    },
    {
      name: '7月',
      value: 7,
    },
    {
      name: '8月',
      value: 8,
    },
    {
      name: '9月',
      value: 9,
    },
    {
      name: '10月',
      value: 10,
    },
    {
      name: '11月',
      value: 11,
    },
    {
      name: '12月',
      value: 12,
    },
  ]

  useEffect(() => {
    if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
      if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
        !paddingBoo && setPaddingBoo(true)
      } else {
        paddingBoo && setPaddingBoo(false)
      }
    }
  }, [tableBigBox?.clientHeight, tableBox?.clientHeight, arrList])

  const headerArr = [
    {
      key: 0,
      name: '项目名',
      width: '6%',
    },
    {
      key: 1,
      name: '月份',
      width: '4%',
    },
    {
      key: 2,
      name: '收历年旧欠',
      width: '10%',
    },
    {
      key: 3,
      name: '收上年旧欠',
      width: '10%',
    },
    {
      key: 4,
      name: '收当年欠',
      width: '10%',
    },
    {
      key: 5,
      name: '收当月',
      width: '10%',
    },
    {
      key: 6,
      name: '收当年',
      width: '10%',
    },
    {
      key: 7,
      name: '收明年',
      width: '10%',
    },
    {
      key: 8,
      name: '当月实收',
      width: '10%',
    },
    {
      key: 9,
      name: '摊当月',
      width: '10%',
    },
    {
      key: 10,
      name: '当月收入',
      width: '10%',
    },
  ]

  const getTotalNum = (arr: any) => {
    let historicalArrearsNum: any = 0
    let preYearArrearsNum: any = 0
    let currentYearArrearsNum: any = 0
    let currentMonthActualNum: any = 0
    let currentYearPrepaidActualNum: any = 0
    let nextYearPrepaidActualNum: any = 0
    let totalNum: any = 0
    let preYearAllocCurrentMonthNum: any = 0
    let thisYearAllocCurrentMonthNum: any = 0
    arr?.map((item: any, i: any) => {
      historicalArrearsNum += item.historicalArrears
      preYearArrearsNum += item.preYearArrears
      currentYearArrearsNum += item.currentYearArrears
      currentMonthActualNum += item.currentMonthActual
      currentYearPrepaidActualNum += item.currentYearPrepaidActual
      nextYearPrepaidActualNum += item.nextYearPrepaidActual
      totalNum +=
        item.historicalArrears +
        item.preYearArrears +
        item.currentYearArrears +
        item.currentMonthActual +
        item.currentYearPrepaidActual +
        item.nextYearPrepaidActual
      preYearAllocCurrentMonthNum += item.preYearAllocCurrentMonth
      thisYearAllocCurrentMonthNum += item.thisYearAllocCurrentMonth

      if (i === arr?.length - 1) {
        setTotalObj({
          historicalArrears: historicalArrearsNum,
          preYearArrears: preYearArrearsNum,
          currentYearArrears: currentYearArrearsNum,
          currentMonthActual: currentMonthActualNum,
          currentYearPrepaidActual: currentYearPrepaidActualNum,
          nextYearPrepaidActual: nextYearPrepaidActualNum,
          total: totalNum,
          preYearAllocCurrentMonth: preYearAllocCurrentMonthNum,
          thisYearAllocCurrentMonth: thisYearAllocCurrentMonthNum,
        })
      }
    })
  }

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:L${(res?.length || 0) + 1}`,
      '!ref': `A1:L${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '项目名' },
      B1: { t: 's', v: '月份' },
      C1: { t: 's', v: '收历年旧欠' },
      D1: { t: 's', v: '收上年旧欠' },
      E1: { t: 's', v: '收当年欠' },
      F1: { t: 's', v: '收当月' },
      G1: { t: 's', v: '收当年' },
      H1: { t: 's', v: '收明年' },
      I1: { t: 's', v: '当月实收' },
      J1: { t: 's', v: '去年预收分摊' },
      K1: { t: 's', v: '今年预收分摊' },
      L1: { t: 's', v: '当月收入' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.name || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.month || '' }
      arrObj[`C${i + 2}`] = { t: 'n', v: item.historicalArrears || 0 }
      arrObj[`D${i + 2}`] = { t: 'n', v: item.preYearArrears || 0 }
      arrObj[`E${i + 2}`] = { t: 'n', v: item.currentYearArrears || 0 }
      arrObj[`F${i + 2}`] = { t: 'n', v: item.currentMonthActual || 0 }
      arrObj[`G${i + 2}`] = { t: 'n', v: item.currentYearPrepaidActual || 0 }
      arrObj[`H${i + 2}`] = { t: 'n', v: item.nextYearPrepaidActual || 0 }
      arrObj[`I${i + 2}`] = {
        t: 'n',
        v:
          item.historicalArrears +
            item.preYearArrears +
            item.currentYearArrears +
            item.currentMonthActual +
            item.currentYearPrepaidActual +
            item.nextYearPrepaidActual || 0,
      }
      arrObj[`J${i + 2}`] = { t: 'n', v: item.preYearAllocCurrentMonth || 0 }
      arrObj[`K${i + 2}`] = { t: 'n', v: item.thisYearAllocCurrentMonth || 0 }
      arrObj[`L${i + 2}`] = {
        t: 'n',
        v:
          item.historicalArrears +
            item.preYearArrears +
            item.currentYearArrears +
            item.currentMonthActual +
            item.preYearAllocCurrentMonth +
            item.thisYearAllocCurrentMonth || 0,
      }
    })
    setArrExportList(arrObj)
  }

  const getArr = (arr: any) => {
    const newArr: any = []
    if (monthValue[0] && monthValue[1]) {
      newArr.push(
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...arr?.filter((item: any) => item.month >= Number(monthValue[0]) && item.month <= Number(monthValue[1])),
      )
    } else {
      newArr.push(...arr)
    }
    return newArr
  }

  const getInterfaceArr = (date: any) => {
    if (dateObj?.typeValue) {
      switch (dateObj?.typeValue) {
        case '26':
          getManagementFee(date)
            .then((res: any) => setAllArr([...res]))
            .catch(console.error)
          break
        case '27':
          getRent(date)
            .then((res: any) => setAllArr([...res]))
            .catch(console.error)
          break
        case '28':
          getShopManagementFee(date)
            .then((res: any) => setAllArr([...res]))
            .catch(console.error)
          break
        default:
          break
      }
    }
  }

  const getReportArr = (id: any) => {
    const newArr = id !== 'all' && id !== '' ? getArr(allArr?.filter((n: any) => n.id === id)) : getArr(allArr)
    if (id === 'all') {
      const nameArr: any = []
      const newAllArr: any = []
      newArr?.map((item: any) => nameArr.push(item.name))
      Array.from(new Set(nameArr))?.map((nameItem: any) => {
        let historicalArrearsNum: any = 0
        let preYearArrearsNum: any = 0
        let currentYearArrearsNum: any = 0
        let currentMonthActualNum: any = 0
        let currentYearPrepaidActualNum: any = 0
        let nextYearPrepaidActualNum: any = 0
        let totalNum: any = 0
        let preYearAllocCurrentMonthNum: any = 0
        let thisYearAllocCurrentMonthNum: any = 0
        newArr
          ?.filter((newItem: any) => newItem.name === nameItem)
          ?.map((item: any, i: any) => {
            historicalArrearsNum += item.historicalArrears
            preYearArrearsNum += item.preYearArrears
            currentYearArrearsNum += item.currentYearArrears
            currentMonthActualNum += item.currentMonthActual
            currentYearPrepaidActualNum += item.currentYearPrepaidActual
            nextYearPrepaidActualNum += item.nextYearPrepaidActual
            totalNum +=
              item.historicalArrears +
              item.preYearArrears +
              item.currentYearArrears +
              item.currentMonthActual +
              item.currentYearPrepaidActual +
              item.nextYearPrepaidActual
            preYearAllocCurrentMonthNum += item.preYearAllocCurrentMonth
            thisYearAllocCurrentMonthNum += item.thisYearAllocCurrentMonth

            if (i === newArr?.filter((newItem: any) => newItem.name === nameItem)?.length - 1) {
              newAllArr.push({
                name: nameItem,
                month: '',
                historicalArrears: historicalArrearsNum,
                preYearArrears: preYearArrearsNum,
                currentYearArrears: currentYearArrearsNum,
                currentMonthActual: currentMonthActualNum,
                currentYearPrepaidActual: currentYearPrepaidActualNum,
                nextYearPrepaidActual: nextYearPrepaidActualNum,
                total: totalNum,
                preYearAllocCurrentMonth: preYearAllocCurrentMonthNum,
                thisYearAllocCurrentMonth: thisYearAllocCurrentMonthNum,
              })
            }
          })
      })
      getTotalNum(newAllArr)
      setArrList(newAllArr)
      exportObj(newAllArr)
    } else {
      getTotalNum(newArr)
      setArrList(newArr)
      exportObj(newArr)
    }
  }

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getExcelName = () => {
    let newName: any = ''
    switch (dateObj?.typeValue) {
      case '26':
        newName = '管理费收费'
        break
      case '27':
        newName = '租金收费'
        break
      case '28':
        newName = '商铺管理费'
        break
    }
    return newName
  }

  useEffect(() => {
    setMonthValue([1, 12])
    setArrList([])
    setPaddingBoo(true)
    setTotalObj({
      historicalArrears: 0,
      preYearArrears: 0,
      currentYearArrears: 0,
      currentMonthActual: 0,
      currentYearPrepaidActual: 0,
      nextYearPrepaidActual: 0,
      total: 0,
      preYearAllocCurrentMonth: 0,
      thisYearAllocCurrentMonth: 0,
    })
    if (dateObj?.newId) {
      setSelectValue(dateObj?.newId)
    }
    if (dateObj?.date) {
      setDateValue(dateObj?.date)
    }
    if (
      dateObj?.newId &&
      dateObj?.date &&
      (dateObj?.typeValue === '26' || dateObj?.typeValue === '27' || dateObj?.typeValue === '28')
    ) {
      getInterfaceArr(dateObj?.date || '')
    }
  }, [dateObj?.newId, dateObj?.date, dateObj?.typeValue])

  useEffect(() => {
    getReportArr(selectValue)
  }, [allArr])

  useEffect(() => {
    getParkingLotArr()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {!urlName.includes('management') && (
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区： </div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    <MenuItem value="all">全部</MenuItem>
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>时间： </div>
            <div className={classes.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    ampm={false}
                    disableFuture
                    format="YYYY"
                    views={['year']}
                    value={dayjs(dateValue)}
                    onChange={(e: any) => {
                      setDateValue(dayjs(e).format('YYYY'))
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <div className={classNames([classes.selectBigBox, classes.selectWidth])}>
            <div className={classes.titleText}>月份： </div>
            <FormControl className={classNames([classes.iptModal, classes.iptModalWidth])} fullWidth>
              <Select
                labelId="demo-simple-select-label"
                value={monthValue[0]}
                onChange={(event: any) => {
                  setMonthValue([event.target.value, monthValue[1]])
                }}
              >
                {monthArr?.map((item: any, i: number) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            -
            <FormControl className={classNames([classes.iptModal, classes.iptModalWidth])} fullWidth>
              <Select
                labelId="demo-simple-select-label"
                value={monthValue[1]}
                onChange={(event: any) => {
                  setMonthValue([monthValue[0], event.target.value])
                }}
              >
                {monthArr?.map((item: any, i: number) => {
                  return (
                    <MenuItem key={i} value={item.value}>
                      {item.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              getInterfaceArr(dateValue)
              navigate(
                `?id=${selectValue === 'all' ? dateObj?.id : selectValue}&date=${dateValue}&typeValue=${
                  dateObj?.typeValue
                }&newId=${selectValue}`,
              )
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          {divWidth > 1280 && (
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                if (selectValue !== '' && dateValue !== '' && arrList?.length > 0) {
                  exportExcel(
                    arrExportList,
                    `${
                      selectValue === 'all'
                        ? '全部小区'
                        : arrParkingLotList.filter((res: any) => res.id === selectValue)[0]?.name
                    }${getExcelName()}-月报-${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              导出
            </Button>
          )}
        </div>
      </div>
      <div className={classes.iphoneBox}>
        <div
          id="tableBigBox"
          style={{
            maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`,
          }}
          className={classes.tableBox}
        >
          <Table id="tableBox" stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {headerArr?.map((item: any) => {
                  return (
                    <StyledTableCell style={{ width: item.width }} key={item.key} align="right">
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrList?.map((item: any, i: number) => {
                const totalNum =
                  item.historicalArrears +
                  item.preYearArrears +
                  item.currentYearArrears +
                  item.currentMonthActual +
                  item.currentYearPrepaidActual +
                  item.nextYearPrepaidActual
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.name || ''}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.month || ''}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.historicalArrears?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.preYearArrears?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.currentYearArrears?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.currentMonthActual?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.currentYearPrepaidActual?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.nextYearPrepaidActual?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{totalNum?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <BootstrapTooltip
                        title={`去年预收分摊：${item.preYearAllocCurrentMonth?.toFixed(2) || 0} + 今年预收分摊：${
                          item.thisYearAllocCurrentMonth?.toFixed(2) || 0
                        }`}
                        placement="left"
                      >
                        <div className={classes.thBox}>
                          {(item.preYearAllocCurrentMonth + item.thisYearAllocCurrentMonth)?.toFixed(2) || 0}
                        </div>
                      </BootstrapTooltip>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>
                        {(
                          item.preYearAllocCurrentMonth +
                          item.thisYearAllocCurrentMonth +
                          item.historicalArrears +
                          item.preYearArrears +
                          item.currentYearArrears +
                          item.currentMonthActual
                        )?.toFixed(2) || 0}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div id="totalBox" style={{ paddingRight: paddingBoo ? 0 : 15 }} className={classes.totalBox}>
          <div style={{ width: '6%' }}>总计</div>
          <div style={{ width: '4%' }} />
          <div>{totalObj?.historicalArrears?.toFixed(2) || 0}</div>
          <div>{totalObj?.preYearArrears?.toFixed(2) || 0}</div>
          <div>{totalObj?.currentYearArrears?.toFixed(2) || 0}</div>
          <div>{totalObj?.currentMonthActual?.toFixed(2) || 0}</div>
          <div>{totalObj?.currentYearPrepaidActual?.toFixed(2) || 0}</div>
          <div>{totalObj?.nextYearPrepaidActual?.toFixed(2) || 0}</div>
          <div>{totalObj?.total?.toFixed(2) || 0}</div>
          <div>{(totalObj.preYearAllocCurrentMonth + totalObj.thisYearAllocCurrentMonth)?.toFixed(2) || 0}</div>
          <div>
            {(
              totalObj.preYearAllocCurrentMonth +
              totalObj.thisYearAllocCurrentMonth +
              totalObj.historicalArrears +
              totalObj.preYearArrears +
              totalObj.currentYearArrears +
              totalObj.currentMonthActual
            )?.toFixed(2) || 0}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MonthlyReportTwoBox
