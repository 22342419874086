import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import SynchronizeBox from '@/components/adminBox/otherFunctions/synchronizeBox'
import { getProjectList, getQueryParamsObj } from '@/api'
import Masonry from '@mui/lab/Masonry'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
    overflow: 'auto',
  },
}))

interface OtherFunctionsBoxProps {
  divWidth: any
}

const OtherFunctionsBox = (props: OtherFunctionsBoxProps) => {
  const { classes } = useStyles()
  const { divWidth } = props
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const dateObj = getQueryParamsObj(window.location?.search)
  const btnList = [
    {
      id: 1,
      name: '应收账至管理处',
      iptName: ['时间', ''],
      iptType: ['date', 'checkbox'],
    },
    {
      id: 2,
      name: '账单审计',
      iptName: ['时间'],
      iptType: ['date'],
    },
    {
      id: 3,
      name: '业主账单-账单日期',
      iptName: ['时间'],
      iptType: ['month'],
    },
    {
      id: 4,
      name: '业主账单-支付日期',
      iptName: ['时间'],
      iptType: ['day'],
    },
    {
      id: 5,
      name: '车场收款报表至管理处',
      iptName: ['年份', '项目', ''],
      iptType: ['year', 'project', 'checkbox'],
    },
    {
      id: 6,
      name: '车辆数据',
      iptName: ['项目'],
      iptType: ['projectId'],
    },
    {
      id: 7,
      name: '票据',
      iptName: ['时间'],
      iptType: ['day'],
    },
    {
      id: 8,
      name: '项目数据',
    },
    {
      id: 9,
      name: '通联对账单',
      iptName: ['时间'],
      iptType: ['date'],
    },
    {
      id: 10,
      name: '非日常收费',
      iptName: ['项目', '时间'],
      iptType: ['projectId', 'date'],
    },
    {
      id: 11,
      name: '重复车牌',
    },
  ]

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  useEffect(() => {
    getParkingLotArr()
  }, [])

  return (
    <div className={classes.root}>
      <Masonry columns={divWidth > 1280 ? 5 : 1} spacing={2}>
        {btnList?.map((item) => {
          return (
            <SynchronizeBox dateObj={dateObj} key={item?.id} dataObject={item} arrParkingLotList={arrParkingLotList} />
          )
        })}
      </Masonry>
    </div>
  )
}

export default OtherFunctionsBox
