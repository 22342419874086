import React from 'react'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'
import { Collapse } from '@mui/material'
import { getChildHrefStr, getQueryParamsObj } from '@/api'
import { useParams, Link } from 'react-router-dom'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  btnBox: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '@media (min-width: 1281px)': {
      '&:hover': {
        background: '#214162',
        color: '#fff',
        cursor: 'pointer',
      },
    },
  },
  btnBoxBg: {
    background: '#214162',
    color: '#fff',
  },
}))

interface CollapseBoxProps {
  openBoo: boolean
  list: any
  idNum: any
  id: any
  titleName: any
  open: any
  getOpenValue: () => void
  divWidth: any
}

const CollapseBox = (props: CollapseBoxProps) => {
  const { classes } = useStyles()
  const { urlId } = useParams()
  const { openBoo, list, idNum, getOpenValue, open, id, divWidth } = props
  const urlName = window.location.pathname.split(`${urlId}`)[0]
  const urlObj = getQueryParamsObj(window.location?.search)

  return (
    <Collapse in={openBoo} timeout="auto" unmountOnExit>
      {list?.map((item: any, i: any) => {
        return (
          <Link
            key={i}
            to={getChildHrefStr(item, id, urlName, urlObj)}
            onClick={() => {
              if (divWidth <= 1280) {
                open && getOpenValue()
              }
            }}
            style={{ paddingLeft: 30 }}
            className={
              idNum === item.id ? classNames([classes.btnBox, classes.btnBoxBg]) : classNames([classes.btnBox])
            }
          >
            {item.name}
          </Link>
        )
      })}
    </Collapse>
  )
}

export default CollapseBox
