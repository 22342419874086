import React, { useEffect } from 'react'
import { makeStyles } from 'tss-react/mui'

type Props = {
  urlId: any
  videoRef: any
  uuid: any
}

const useStyles = makeStyles()(() => ({
  size: {
    width: '100%',
    height: '100%',
  },
  loadingBox: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
}))

const PlayerOne = (props: Props) => {
  const { videoRef, urlId, uuid } = props
  const { classes } = useStyles()
  const handleEnded = (event: any) => {
    if (event.target.paused) {
      console.info('paused')
    }
  }

  // eslint-disable-next-line prefer-const
  let mseQueue: any[] = [],
    mseSourceBuffer: any,
    mseStreamingStarted = false,
    videoSound = false

  function Utf8ArrayToStr(array: any) {
    let out, i, len, c
    let char2, char3

    out = ''
    // eslint-disable-next-line prefer-const
    len = array.length
    i = 0
    while (i < len) {
      c = array[i++]
      switch (c >> 4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c)
          break
        case 12:
        case 13:
          // 110x xxxx 10xx xxxx
          char2 = array[i++]
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f))
          break
        case 14:
          // 1110 xxxx 10xx xxxx 10xx xxxx
          char2 = array[i++]
          char3 = array[i++]
          out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0))
          break
      }
    }

    return out
  }

  function startPlay() {
    const video: any = document.getElementById(`video${urlId}`)
    const url = `ws://127.0.0.1:8083/stream/${uuid}/channel/${urlId}/mse?uuid=${uuid}&channel=${urlId}`
    const mse = new MediaSource()
    video.src = window.URL.createObjectURL(mse)
    mse.addEventListener(
      'sourceopen',
      function () {
        const ws = new WebSocket(url)
        ws.binaryType = 'arraybuffer'
        ws.onmessage = function (event) {
          const data = new Uint8Array(event.data)
          let decoded_arr
          let mimeCodec
          if (data[0] == 9) {
            decoded_arr = data.slice(1)
            if (window.TextDecoder) {
              mimeCodec = new TextDecoder('utf-8').decode(decoded_arr)
            } else {
              mimeCodec = Utf8ArrayToStr(decoded_arr)
            }
            if (mimeCodec.indexOf(',') > 0) {
              videoSound = true
            }
            mseSourceBuffer = mse.addSourceBuffer('video/mp4; codecs="' + mimeCodec + '"')
            mseSourceBuffer.mode = 'segments'
            mseSourceBuffer.addEventListener('updateend', pushPacket)
          } else {
            readPacket(event.data)
          }
        }
      },
      false,
    )
    const playStatus = video.play()
    if (playStatus !== undefined) {
      playStatus
        .then(() => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(() => {
          // AutoPlay was prevented
          // Show paused UI.
        })
    }
  }

  function pushPacket() {
    const video: any = document.getElementById(`video${urlId}`)
    let packet
    if (!mseSourceBuffer.updating) {
      if (mseQueue.length > 0) {
        packet = mseQueue.shift()
        mseSourceBuffer.appendBuffer(packet)
      } else {
        mseStreamingStarted = false
      }
    }
    if (video?.buffered.length > 0) {
      if (typeof document.hidden !== 'undefined' && document.hidden && !videoSound) {
        //no sound, browser paused video without sound in background
        video.currentTime = video.buffered.end(video.buffered.length - 1) - 0.5
      }
    }
  }

  function readPacket(packet: any) {
    if (!mseStreamingStarted) {
      mseSourceBuffer.appendBuffer(packet)
      mseStreamingStarted = true
      return
    }
    mseQueue.push(packet)
    if (!mseSourceBuffer.updating) {
      pushPacket()
    }
  }

  useEffect(() => {
    startPlay()
  }, [])

  return (
    <>
      <video
        id={`video${urlId}`}
        ref={videoRef}
        controls={false}
        className={classes.size}
        preload="auto"
        onEnded={handleEnded}
      />
    </>
  )
}

export default PlayerOne
