import React, { useEffect } from 'react'
import { getPoiList, getScenegraphArrList, getViewPort } from '@/api'
import { ScenegraphLayer } from '@deck.gl/mesh-layers/typed'
import { load } from '@loaders.gl/core'
import { GLTFLoader } from '@loaders.gl/gltf'

interface BuildingBoxProps {
  map: any
  arr: any
  configJson: any
  getLayerArr: any
  allArr: any
  id: any
}

let timer: NodeJS.Timeout
function DeckGlScenegraphBox(props: BuildingBoxProps) {
  const { map, arr, configJson, getLayerArr, allArr, id } = props

  const getScenegraph = () => {
    const newLayerArr: any = []
    arr?.map((res: any) => {
      getPoiList('deckGlScenegraph', res.toString(), getViewPort(map?.deck?.viewManager?._viewports[0]?.getBounds()))
        ?.then((success: any) => {
          load(configJson[id][res[0]]?.default?.iconUrl, GLTFLoader, {
            gltf: { postProcess: true, normalize: true },
          }).then((newSuccess) => {
            const layer = new ScenegraphLayer({
              id: `${res.toString()}-deckGlScenegraph-layer`,
              data: getScenegraphArrList(success, res, configJson, id)?.features,
              pickable: true,
              scenegraph: newSuccess,
              getPosition: (d) => d.coordinates,
              getOrientation: (d) => d.properties.orientation || [0, 0, 90],
              sizeScale: configJson[id][res[0]]?.default?.iconSize,
              _animations: {
                '*': { speed: 5 },
              },
              _lighting: 'pbr',
            })
            newLayerArr.push(layer)
            if (newLayerArr?.length === arr?.length) {
              getLayerArr(newLayerArr, allArr)
            }
          })
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    if (map) {
      getScenegraph()
      clearInterval(timer)
      timer = setInterval(() => {
        getScenegraph()
      }, 3000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [map, arr, allArr])

  return <></>
}

export default DeckGlScenegraphBox
