const prod: { url: { API_URL: string; SETTING_URL: string; URL_SETTING_URL: string; PARKING_URL: string } } = {
  url: {
    API_URL: 'https://smapi.hoevo.com',
    SETTING_URL: 'https://hoevo-smart-community.oss-cn-shanghai.aliyuncs.com/districtMapStatic/setting.json',
    URL_SETTING_URL: 'https://hoevo-smart-community.oss-cn-shanghai.aliyuncs.com/districtMapStatic/urlSetting.json',
    PARKING_URL: 'https://hoevo-smart-community.oss-cn-shanghai.aliyuncs.com/districtMapStatic/parking.json',
  },
}
const dev: { url: { API_URL: string; SETTING_URL: string; URL_SETTING_URL: string; PARKING_URL: string } } = {
  url: {
    API_URL: 'http://localhost:8080',
    SETTING_URL: 'http://localhost:8080/districtMapStatic/setting.json',
    URL_SETTING_URL: 'http://localhost:8080/districtMapStatic/urlSetting.json',
    PARKING_URL: 'http://localhost:8080/districtMapStatic/parking.json',
  },
}
export const config = process.env.NODE_ENV === 'development' ? dev : prod
// export const config = prod
