import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { updateInvoice } from '@/api'
import TextField from '@mui/material/TextField'

const useStyles = makeStyles()(() => ({
  textField: {
    maxWidth: 118,
    width: '100%',
    '@media (max-width: 1280px)': {
      maxWidth: 81,
      fontSize: '1.4vh',
    },
  },
  underline: {
    '&::before,&::after': {
      display: 'none',
    },
  },
  dateInput: {
    width: 'calc( 100% - 4px )',
    textAlign: 'center',
    color: '#000',
    fontSize: '1.8vh',
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
    height: '100%',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

interface TextFieldBoxProps {
  payTime: any
  receiptNumber: any
  timeBoo: any
  getTimeBoo: (boo: any) => void
}

const TextFieldBox = (props: TextFieldBoxProps) => {
  const { classes } = useStyles()
  const { payTime, receiptNumber, getTimeBoo, timeBoo } = props
  const [dateValue, setDateValue] = useState('')

  useEffect(() => {
    setDateValue(payTime || '')
  }, [])

  return (
    <TextField
      id={`date-start-${receiptNumber}`}
      name={`date-start-${receiptNumber}`}
      type="date"
      value={dateValue || ''}
      className={classes.textField}
      onChange={(event: any) => {
        updateInvoice(receiptNumber, event.target.value === '' ? '未收款' : '已收款', event.target.value).catch(
          console.error,
        )
        if (timeBoo !== (event.target.value !== '')) {
          getTimeBoo(event.target.value !== '')
        }
        setDateValue(event.target.value)
      }}
      InputProps={{
        classes: {
          input: classes.dateInput,
          underline: classes.underline,
        },
      }}
    />
  )
}

export default TextFieldBox
