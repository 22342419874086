import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import { exportExcel, getQueryParamsObj, getUnbilledData } from '@/api'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import moment from 'moment'
import UnbilledDataCollapseBox from '@/components/adminBox/unbilledDataBox/unbilledDataCollapseBox'
import LoadingBox from '@/components/baseMap/loadingBox'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    lineHeight: '34px',
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '15%',
    },
  },
  titleLeftBox: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#000',
    height: '100%',
    width: '40%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: 'auto',
      width: '90%',
      justifyContent: 'space-between',
    },
  },
  selectBigBox: {
    width: '45%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '100%',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  tableBox: {
    minWidth: 1000,
  },
  loadBox: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    right: 0,
    top: '15%',
    margin: 'auto',
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  iphoneBox: {
    height: '93%',
    overflow: 'auto',
    position: 'relative',
    '@media (max-width: 1280px)': {
      height: '85%',
      overflow: 'auto',
    },
  },
  textField: {
    width: '65%',
    textAlign: 'center',
    color: '#000',
    fontSize: 20,
    height: 40,
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
    background: 'transparent',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    '&::before,&::after': {
      display: 'none',
    },
  },
}))

interface UnbilledDataBoxProps {
  divWidth: any
}

const UnbilledDataBox = (props: UnbilledDataBoxProps) => {
  const { divWidth } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [loadBoo, setLoadBoo] = useState<any>(true)
  const [arrList, setArrList] = useState<any>([])
  const [arrNameList, setArrNameList] = useState<any>([])
  const [arrExportList, setArrExportList] = useState<any>({})
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>('')

  const totalBox = document.getElementById('totalBox')

  const headerArr = [
    {
      key: 0,
      name: '小区名',
      width: '10%',
    },
    {
      key: 1,
      name: '收费项目',
      width: '18%',
    },
    {
      key: 2,
      name: '当月实际入账',
      width: '18%',
    },
    {
      key: 3,
      name: '当月实际预收',
      width: '18%',
    },
    {
      key: 4,
      name: '当月分摊预收',
      width: '18%',
    },
    {
      key: 5,
      name: '还需分摊预收',
      width: '18%',
    },
  ]

  const getEnglishLetter = (num: any) => {
    let newLetter
    if (num <= 26) {
      newLetter = String.fromCharCode(num + 64)
    } else {
      const remainder = num % 26
      const newValue = Math.floor(num / 26)
      if (remainder === 0) {
        newLetter = String.fromCharCode(newValue - 1 + 64, 26 + 64)
      } else {
        newLetter = String.fromCharCode(newValue + 64, remainder + 64)
      }
    }

    return newLetter
  }

  const getEnglishLetter1 = (num: any) => {
    let newLetter
    if (num <= 26) {
      newLetter = String.fromCharCode(num + 64)
    } else {
      const remainder = num % 26
      const newValue = Math.floor(num / 26)
      if (remainder === 0) {
        newLetter = String.fromCharCode(newValue - 1 + 64, 26 + 64)
      } else {
        newLetter = String.fromCharCode(newValue + 64, remainder + 64)
      }
    }

    return newLetter
  }

  const exportObj = (res: any, data: any) => {
    if (res?.maxDate) {
      const monthArr: any = []
      let keyObj: any = {}
      let num = Number(moment.utc(data).format('MM'))
      let yearStr: any = ''
      while (
        !(
          moment(yearStr, 'YYYY-MM-DD').valueOf() ===
          moment(
            `${moment.utc(res?.maxDate).format('YYYY')}-${Number(moment.utc(res?.maxDate).format('MM'))}-1`,
            'YYYY-MM-DD',
          ).valueOf()
        )
      ) {
        yearStr = `${Number(moment.utc(moment(data).month(num)).format('YYYY'))}-${Number(
          moment.utc(moment(data).month(num)).format('MM'),
        )}`
        monthArr.push(
          `${Number(moment.utc(moment(data).month(num)).format('YYYY'))}-${Number(
            moment.utc(moment(data).month(num)).format('MM'),
          )}`,
        )
        num++
      }
      monthArr?.map((res: any, i: any) => {
        keyObj = { ...keyObj, [`${getEnglishLetter(i + 7)}1`]: { t: 's', v: res } }
      })

      const arrObj = {
        '!cols': [],
        '!fullref': `A1:${getEnglishLetter1(monthArr?.length + 6)}${(res?.data?.length || 0) + 1}`,
        '!ref': `A1:${getEnglishLetter1(monthArr?.length + 6)}${(res?.data?.length || 0) + 1}`,
        '!rows': [],
        A1: { t: 's', v: '小区名' },
        B1: { t: 's', v: '收费项目' },
        C1: { t: 's', v: '当月实际入账' },
        D1: { t: 's', v: '当月实际预收' },
        E1: { t: 's', v: '当月分摊预收' },
        F1: { t: 's', v: '还需分摊预收' },
        ...keyObj,
      }

      res?.data?.forEach((item: any, i: number) => {
        arrObj[`A${i + 2}`] = { t: 's', v: item.name || '' }
        arrObj[`B${i + 2}`] = { t: 's', v: item.charge_name || '' }
        arrObj[`C${i + 2}`] = { t: 'n', v: item.current_month_income || 0 }
        arrObj[`D${i + 2}`] = { t: 'n', v: item.current_month_prepay || 0 }
        arrObj[`E${i + 2}`] = { t: 'n', v: item.current_month_apportion_prepayment || 0 }
        arrObj[`F${i + 2}`] = { t: 'n', v: item.remain_prepayment || 0 }
        monthArr?.map((res: any, x: any) => {
          arrObj[`${getEnglishLetter(x + 7)}${i + 2}`] = { t: 'n', v: item[res] || 0 }
        })
      })

      setArrExportList(arrObj)
    }
  }

  const getUnbilledDataArr = (date: any) => {
    setArrList([])
    setArrNameList([])
    getUnbilledData(`${date}-01`)
      .then((res: any) => {
        const newArr: any[] = []
        const newNameArr: any[] = []
        res?.data?.map((item: any) => {
          newNameArr.push(item.name)
        })
        Array.from(new Set(newNameArr))?.map((item) => {
          newArr.push({ projectName: item, open: false })
        })
        setLoadBoo(false)
        setArrNameList(newArr)
        setArrList(res?.data)
        exportObj(res, date)
      })
      .catch(console.error)
  }

  useEffect(() => {
    setArrList([])
    if (dateObj?.date && window.location.pathname.includes('24')) {
      setDateValue(dateObj?.date)
      getUnbilledDataArr(dateObj?.date)
    }
  }, [dateObj?.id, dateObj?.date, dateObj?.typeValue])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>时间： </div>
            <input
              type="month"
              // @ts-ignore
              min="2023-12"
              value={dateValue}
              className={classes.textField}
              onChange={(event: any) => {
                setDateValue(event.target.value)
              }}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              setLoadBoo(true)
              getUnbilledDataArr(dateValue)
              navigate(`?id=${dateObj?.id || 101}&date=${dateValue}`)
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          {divWidth > 1280 && (
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => {
                if (dateValue !== '' && arrList?.length > 0) {
                  exportExcel(
                    arrExportList,
                    `分摊未开票票据-${moment.utc(dateValue || new Date()).format('YYYY-MM')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              导出
            </Button>
          )}
        </div>
      </div>
      <div className={classes.iphoneBox}>
        {loadBoo && (
          <div className={classes.loadBox}>
            <LoadingBox />
          </div>
        )}
        <div
          id="tableBigBox"
          style={{
            maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`,
          }}
          className={classes.tableBox}
        >
          <Table id="tableBox" stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {headerArr?.map((item: any) => {
                  return (
                    <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrNameList?.map((item: any, i: number) => {
                return (
                  <React.Fragment key={i}>
                    <StyledTableRow>
                      <StyledTableCell align="right">
                        <div
                          onClick={() => {
                            const newBooArr: any[] = []
                            arrNameList?.map((items: any) => {
                              if (items.projectName === item.projectName) {
                                newBooArr.push({ ...items, open: !items.open })
                              } else {
                                newBooArr.push(items)
                              }
                            })
                            setArrNameList([...newBooArr])
                          }}
                          className={classes.thBox1}
                        >
                          {item.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          {item.projectName || ''}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right" />
                      <StyledTableCell align="right" />
                      <StyledTableCell align="right" />
                      <StyledTableCell align="right" />
                      <StyledTableCell align="right" />
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: 0 }} align="right" colSpan={6}>
                        <UnbilledDataCollapseBox
                          openBoo={item.open}
                          allArr={arrList?.filter((res: any) => res.name === item.projectName)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  </React.Fragment>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default UnbilledDataBox
