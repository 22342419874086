import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import { updateInvoice } from '@/api'

const useStyles = makeStyles()(() => ({
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        width: '3vh',
        height: '3vh',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
      fontSize: '1.8vh',
    },
  },
}))

interface FormGroupBoxProps {
  receiptNumber: any
  timeBoo: any
}

const FormGroupBox = (props: FormGroupBoxProps) => {
  const { classes } = useStyles()
  const { receiptNumber, timeBoo } = props
  const [stateStr, setStateStr] = useState(false)

  useEffect(() => {
    setStateStr(timeBoo)
  }, [timeBoo])

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.checkBox}
        control={
          <Checkbox
            checked={stateStr}
            onChange={() => {
              updateInvoice(receiptNumber, !stateStr ? '已收款' : '未收款', '').catch(console.error)
              setStateStr(!stateStr)
            }}
            color="default"
          />
        }
        label=""
      />
    </FormGroup>
  )
}

export default FormGroupBox
