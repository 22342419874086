import createClient from 'openapi-fetch'
import { config } from '@/Constants'
import * as XLSX from 'xlsx'
import moment from 'moment'
import WeeklySummaryBox from '@/components/adminBox/weeklySummaryBox'
export const { GET, POST, PUT, PATCH, DELETE } = createClient({ baseUrl: `${config.url.API_URL}` })

export const getRender = (url: any) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.send(null)
    xhr.onreadystatechange = function () {
      const { status, readyState, response } = this
      const res = {
        data: response,
        status,
      }
      if (readyState === 4) {
        if (status === 200) {
          resolve(res)
        } else {
          reject(res)
        }
      }
    }
  })
}

export const getConfigJson = (url: any) => {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, false) // 文件路径
  xhr.overrideMimeType('text/html;charset=utf-8') // 默认为utf-8
  xhr.send(null)
  if (xhr.readyState === 4) {
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText)
    }
  }
}

// 项目列表
export const getProjectList = async () => {
  // @ts-ignore
  const { data } = await GET('/projects', {})
  return data
}

// 查询项目
export const getProjectObj = async (id: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/{id}', { params: { path: { id: id } } })
  return data
}

// POI列表
export const getPoiList = async (renderType: any, sourceType: any, viewport: any) => {
  // @ts-ignore
  const { data } = await GET('/poi/search', { params: { query: { renderType, sourceType, viewport } } })
  return data
}

// 车位列表
export const getCarList = async (
  ytProjectId: any,
  page: any,
  size: any,
  plateNumber?: any,
  carType?: any,
  address?: any,
) => {
  let obj = { ytProjectId, page, size }
  if (plateNumber && plateNumber !== 'undefined') {
    // @ts-ignore
    obj = { ...obj, plateNumber }
  }
  if (address && address !== 'undefined') {
    // @ts-ignore
    obj = { ...obj, address }
  }
  if (carType && carType !== '全部') {
    // @ts-ignore
    obj = { ...obj, carType }
  }

  // @ts-ignore
  const { data } = await GET('/cars', {
    params: {
      query: obj,
    },
  })
  return data
}

// 车场列表
export const getParkingLotList = async () => {
  // @ts-ignore
  const { data } = await GET('/parking', {})
  return data
}

// 查询车场
export const getParkingLot = async (id: any) => {
  // @ts-ignore
  const { data } = await GET('/parking/{id}', { params: { path: { id } } })
  return data
}

// 审计列表
export const getAuditList = async (id: any, date: any) => {
  // @ts-ignore
  const { data } = await GET('/parking/{id}/audits', { params: { path: { id }, query: { date } } })
  return data
}

// 楼列表
export const getBuildingList = async (projectId: any, accountType: any, shadow: any) => {
  // @ts-ignore
  const { data } = await GET('/analysis/building', {
    params: { query: { projectId, accountType, shadow, page: 0, size: 999999 } },
  })
  return data
}

// 小区汇总
export const getCommunityList = async (year: any, month: any, projectId: any, accountType: any) => {
  let obj: any = { year, month, accountType }
  if (projectId && projectId !== '全部') {
    obj = { ...obj, projectId }
  }
  // @ts-ignore
  const { data } = await GET('/analysis/project', {
    params: { query: obj },
  })
  return data
}

// 业主列表
export const getOwnerList = async (projectId: any, accountType: any, shadow: any, address?: any, buildingId?: any) => {
  let obj: any = { projectId, accountType, shadow, page: 0, size: 999999 }
  if (buildingId) {
    obj = { ...obj, buildingId }
  }
  if (address) {
    obj = { ...obj, address }
  }
  // @ts-ignore
  const { data } = await GET('/analysis/owner', {
    params: { query: obj },
  })
  return data
}

// 车场收费日报
export const getYardDaily = async (id: any, startDay: any, endDay: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/{id}/income/daily', { params: { path: { id }, query: { startDay, endDay } } })
  return data
}

// 车场收费月报
export const getMonthlyReport = async (year: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/income/month', { params: { query: { year } } })
  return data
}

// 管理费月报
export const getManagementFee = async (year: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/income/management', { params: { query: { year } } })
  return data
}

// 租金月报
export const getRent = async (year: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/income/recent', { params: { query: { year } } })
  return data
}

// 商铺管理费月报
export const getShopManagementFee = async (year: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/income/shop', { params: { query: { year } } })
  return data
}

// 车场收费年报
export const getParkingLotReport = async (projectId: any, year: any, shadow: any) => {
  // @ts-ignore
  const { data } = await GET('/projects/income/year', { params: { query: { projectId, year, shadow } } })
  return data
}

// 车辆统计
export const getCarType = async (projectId: any) => {
  // @ts-ignore
  const { data } = await GET('/cars/count', { params: { query: { projectId } } })
  return data
}

// 临时车辆列表
export const getTemporaryVehicle = async (projectId: any, showAll: any) => {
  // @ts-ignore
  const { data } = await GET('/super_cars', { params: { query: { projectId, showAll } } })
  return data
}

// 创建临时车辆
export const setCar = async (body: any) => {
  // @ts-ignore
  const { data } = await POST('/super_cars', { params: {}, body })
  return data
}

// 同步非日常收费
export const setInvalidCarInvoice = async (project_id: any, date: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/lark/invalid_car_invoice/{project_id}', {
    params: { path: { project_id }, query: { date } },
  })
  return data
}

// 同步应收账至管理处
export const setCollection = async (date: any, shadow: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/analysis', {
    params: { query: { date, shadow } },
  })
  return data
}

// 同步账单审计
export const setBillAudit = async (date: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/audit', {
    params: { query: { date } },
  })
  return data
}

// 同步业主账单
export const setOwnerBill = async (range: any, paymentRange: any, ownerId: any) => {
  let obj = {}
  if (range !== '') {
    obj = { ...obj, range }
  }
  if (paymentRange !== '') {
    obj = { ...obj, paymentRange }
  }
  if (ownerId !== '') {
    obj = { ...obj, ownerId }
  }
  // @ts-ignore
  const { data } = await POST('/sync/bills', {
    params: { query: obj },
  })
  return data
}

// 同步车场收款报表至管理处
export const setProject = async (year: any, projectId: any, shadow: any) => {
  let obj: any = { year, shadow }
  if (projectId !== '') {
    obj = { ...obj, projectId }
  }
  // @ts-ignore
  const { data } = await POST('/sync/parking/analysis', {
    params: { query: obj },
  })
  return data
}
// 同步车辆数据
export const setSynchronizeVehicles = async (projectId: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/car', {
    params: { query: { projectId } },
  })
  return data
}

// 同步票据
export const setSyncTickets = async (range: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/invoice', {
    params: { query: { range } },
  })
  return data
}

// 同步项目
export const setSyncProjects = async () => {
  // @ts-ignore
  const { data } = await POST('/sync/project', {
    params: {},
    body: {},
  })
  return data
}

// 同步通联对账单
export const setAllinpay = async (date: any) => {
  // @ts-ignore
  const { data } = await POST('/sync/allinpay', {
    params: { query: { date } },
    body: { date },
  })
  return data
}

// 同步重复车牌
export const setDuplicatePlateNumber = async () => {
  // @ts-ignore
  const { data } = await POST('/sync/lark/duplicate_plate_number', {
    params: {},
    body: {},
  })
  return data
}

// 票据列表
export const getInvoicing = async (
  startDate: any,
  endDate: any,
  receiptMethod: any,
  projectId?: any,
  filterUnconfirmed?: any,
) => {
  let obj: any = { startDate, endDate, receiptMethod }
  if (projectId && projectId !== '') {
    obj = { ...obj, projectId }
  }
  if (filterUnconfirmed || filterUnconfirmed === 'false') {
    obj = { ...obj, filterUnconfirmed }
  }

  // @ts-ignore
  const { data } = await GET('/invoices', { params: { query: obj } })
  return data
}

// 票据列表
export const getBills = async (ownerId: any, chargeName: any, carId: any, page: any, size: any) => {
  // @ts-ignore
  const { data } = await GET('/bills', { params: { query: { ownerId, chargeName, carId, page, size } } })
  return data
}

// 分摊未开票票据
export const getUnbilledData = async (date: any) => {
  // @ts-ignore
  const { data } = await GET('/analysis/prepay_no_invoice_bills', { params: { query: { date } } })
  return data
}

// 通道事件
export const getChannelEvents = async (
  projectId: any,
  plateNumber: any,
  startTime: any,
  endTime: any,
  page: any,
  size: any,
) => {
  let obj: any = { projectId, startTime, endTime, page, size }
  if (plateNumber && plateNumber !== 'undefined') {
    obj = { ...obj, plateNumber }
  } else {
    obj = { ...obj, plateNumber: '' }
  }
  // @ts-ignore
  const { data } = await GET('/cars/events', { params: { query: obj } })
  return data
}

// 查询小区公益性收支
export const getPublicWelfare = async (projectId: any, year: any, quarter: any) => {
  // @ts-ignore
  const { data }: any = await GET('/loan/{projectId}/{year}/{quarter}', {
    params: { path: { projectId, year, quarter } },
  })
  return data
}

// 查询小区每周统计
export const getWeeklySummary = async (projectId: any, startDate: any, endDate: any) => {
  // @ts-ignore
  const { data }: any = await GET('/analysis/project/week', { params: { query: { projectId, startDate, endDate } } })
  return data
}

// 户主详情
export const getOwnerDetails = async (id: any) => {
  // @ts-ignore
  const { data } = await GET('/owners/{id}', { params: { path: { id } } })
  return data
}

// 创建楼
export const setBuilding = async (buildingId: any, body: any) => {
  // @ts-ignore
  const { data } = await POST('/buildings/{buildingId}/poi', { params: { path: { buildingId } }, body })
  return data
}

// 创建户
export const setHouses = async (buildingId: any, body: any) => {
  // @ts-ignore
  const { data } = await POST('/buildings/{buildingId}/houses/poi', { params: { path: { buildingId } }, body })
  return data
}

// 创建热力
export const setTemperature = async (body: any) => {
  // @ts-ignore
  const { data } = await POST('/poi', { params: {}, body })
  return data
}

// 创建车位
export const setParkingSpace = async (id: any, body: any) => {
  // @ts-ignore
  const { data } = await POST('/parking-space/{id}/poi', { params: { path: { id } }, body })
  return data
}

// 创建小区
export const setCommunity = async (projectId: any, body: any) => {
  // @ts-ignore
  const { data } = await POST('/projects/{projectId}/poi', { params: { path: { projectId } }, body })
  return data
}

// 批量更新审计表
export const setAudits = async (id: any, body: any) => {
  // @ts-ignore
  const { data } = await PUT('/parking/{id}/audits', { params: { path: { id } }, body })
  return data
}

// 更新发票数据
export const updateInvoice = async (invoiceNumber: any, status: any, payTime: any) => {
  // @ts-ignore
  const { data } = await PATCH('/invoices', {
    params: { query: { invoiceNumber, status, payTime: payTime !== '' ? payTime : null } },
  })
  return data
}

// 清空公益收支
export const deletePublicWelfare = async () => {
  // @ts-ignore
  const { data } = await DELETE('/sync/cache', {
    params: {},
  })
  return data
}

export const getQueryParams = (url: any) => {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&')
  const params: any = {}
  const arr: any[] = []
  paramArr.map((param: any) => {
    arr.push(param.split(','))
    // @ts-ignore
    params['source'] = arr
  })
  return params
}

export const getQueryParamsObj = (url: any) => {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&')
  const params: any = {}
  paramArr.map((param: any) => {
    params[param.split('=')[0]] = param.split('=')[1]
  })
  return params
}

export const postRender = (url: any, body: any) => {
  const xhr = new XMLHttpRequest()
  xhr.open('POST', url, false) // 文件路径
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send(JSON.stringify(body))
  if (xhr.readyState === 4) {
    if (xhr.status === 200) {
      return JSON.parse(xhr.responseText)
    }
  }
}

export const getViewPort = (oldArr: any[]) => {
  const newArr: any = []
  oldArr?.map((item) => newArr.push(Number(item.toFixed(3))))
  return JSON.stringify([newArr])
}

export const getArrList = (obj: any, arr: any, configJson: any, id: any) => {
  const newArr: any = []
  if (arr?.length > 1) {
    obj?.features?.map((item: any) => {
      if (configJson[id][arr[0]]?.type[arr[1]][item?.properties[arr[1]]?.toString()]) {
        if (configJson[id][arr[0]]?.type[arr[1]][item?.properties[arr[1]]?.toString()]?.iconUrl !== '') {
          newArr.push({
            ...item,
            properties: {
              ...item?.properties,
              ...configJson[id][arr[0]]?.type[arr[1]][item?.properties[arr[1]]?.toString()],
            },
          })
        }
      } else {
        if (configJson[id][arr[0]]?.type[arr[1]]?.default?.iconUrl !== '') {
          newArr.push({
            ...item,
            properties: {
              ...item?.properties,
              ...configJson[id][arr[0]]?.type[arr[1]]?.default,
            },
          })
        }
      }
    })
    return { ...obj, features: newArr }
  } else {
    if (id === '112') {
      if (configJson[id][arr[0]]?.default?.iconUrl !== '') {
        obj?.features?.map((item: any) => {
          if (item?.geometry?.coordinates?.length > 1 || item?.geometry?.type === 'MultiPoint') {
            item?.geometry?.coordinates?.map((items: any) => {
              newArr.push({
                ...item,
                properties: { ...item?.properties, ...configJson[id][arr[0]]?.default },
                geometry: { coordinates: [...items], type: 'Point' },
              })
            })
          } else {
            newArr.push({ ...item, properties: { ...item?.properties, ...configJson[id][arr[0]]?.default } })
          }
        })
      }
      return { ...obj, features: newArr }
    } else {
      if (configJson[id][arr[0]]?.default?.iconUrl !== '') {
        obj?.features?.map((item: any) => {
          newArr.push({ ...item, properties: { ...item?.properties, ...configJson[id][arr[0]]?.default } })
        })
      }
      return { ...obj, features: newArr }
    }
  }
}

export const getScenegraphArrList = (obj: any, arr: any, configJson: any, id: any) => {
  const newArr: any = []
  if (arr?.length > 1) {
    obj?.features?.map((item: any) => {
      if (configJson[id][arr[0]]?.type[arr[1]][item?.properties[arr[1]]?.toString()]) {
        newArr.push({
          coordinates: item.geometry.coordinates,
          properties: {
            ...item?.properties,
            ...configJson[id][arr[0]]?.type[arr[1]][item?.properties[arr[1]]?.toString()],
            orientation: item?.properties?.orientation,
          },
        })
      } else {
        newArr.push({
          coordinates: item.geometry.coordinates,
          properties: {
            ...item?.properties,
            ...configJson[id][arr[0]]?.type[arr[1]]?.default,
            orientation: item?.properties?.orientation,
          },
        })
      }
    })
    return { ...obj, features: newArr }
  } else {
    obj?.features?.map((item: any) => {
      newArr.push({
        coordinates: item.geometry.coordinates,
        properties: {
          ...item?.properties,
          ...configJson[id][arr[0]]?.default,
          orientation: item?.properties?.orientation,
        },
      })
    })
    return { ...obj, features: newArr }
  }
}

export const arrObj = (configJson: any, id: any) => {
  const mapBoxGeoJSONArr: any = []
  const mapBoxImageArr: any = []
  const mapBoxIconArr: any = []
  const deckGlGeoJSONArr: any = []
  const deckGlImageArr: any = []
  const deckGlIconArr: any = []
  // const deckGlHeatMapArr: any = []
  const deckGlScenegraphArr: any = []
  const mapBoxAllArr: any[] = []
  const deckAllArr: any[] = []
  getQueryParams(window.location?.search)?.source?.map((item: any) => {
    if (configJson[id]) {
      // @ts-ignore
      switch (configJson[id][item[0]]?.renderType) {
        case 'mapBoxGeoJSON':
          mapBoxGeoJSONArr.push(item)
          mapBoxAllArr.push(item.toString())
          if (item[0] === 'building') {
            deckAllArr.push(item.toString())
          }
          break
        case 'mapBoxImage':
          mapBoxImageArr.push(item)
          mapBoxAllArr.push(item.toString())
          break
        case 'mapboxIcon':
          mapBoxIconArr.push(item)
          mapBoxAllArr.push(item.toString())
          break
        case 'deckGlGeoJSON':
          deckGlGeoJSONArr.push(item)
          deckAllArr.push(item.toString())
          break
        case 'deckGlImage':
          deckGlImageArr.push(item)
          deckAllArr.push(item.toString())
          break
        case 'deckGlIcon':
          deckGlIconArr.push(item)
          deckAllArr.push(item.toString())
          break
        case 'deckGlScenegraph':
          deckGlScenegraphArr.push(item)
          deckAllArr.push(item.toString())
          break
        // case 'deckGlHeatMap':
        //   deckGlHeatMapArr.push(item)
        //   deckAllArr.push(item.toString())
        //   break
        default:
          break
      }
    }
  })
  return {
    mapBoxGeoJSONArr,
    mapBoxImageArr,
    mapBoxIconArr,
    deckGlGeoJSONArr,
    deckGlImageArr,
    deckGlIconArr,
    deckGlScenegraphArr,
    // deckGlHeatMapArr,
    mapBoxAllArr,
    deckAllArr,
  }
}

export const tabExportExcel = function (
  tab: any,
  fileName = `${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`,
  keyHead: string | undefined = undefined,
  length: number | undefined = undefined,
) {
  const workbook = XLSX.utils.book_new()
  const sheets = XLSX.utils.table_to_sheet(tab, { raw: true })
  if (keyHead && length) {
    for (let i = 0; i <= length; i++) {
      delete sheets[`${keyHead}${i + 1}`]
    }
  }
  XLSX.utils.book_append_sheet(workbook, sheets, 'Sheet1')
  // 导出 Excel
  XLSX.writeFile(workbook, fileName)
}

export const exportExcel = function (tab: any, fileName = `${moment.utc(new Date()).format('YYYY-MM-DD')}.xlsx`) {
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, tab, 'Sheet1')
  // 导出 Excel
  XLSX.writeFile(workbook, fileName)
}

// 处理数字
export const getNewNumber = (num: any, fixedNum: any) => {
  if (num) {
    if (`${Number.parseInt(num)}`?.length >= 5) {
      return `${(num / 10000)?.toFixed(2)}万`
    } else {
      return Number(num?.toFixed(fixedNum))?.toLocaleString()
    }
  }
}

export const getAdminNewNumber = (num: any, fixedNum: any) => {
  if (num) {
    return num?.toFixed(fixedNum)
  }
}

export const getNewNumberPercentage = (num: any, fixedNum: any) => {
  if (num) {
    return `${Number((num * 100)?.toFixed(fixedNum))}%`
  } else {
    return '0%'
  }
}

export const getChildHrefStr = (item: any, id: any, urlName: any, urlObj: any) => {
  let newHrefStr = '#'
  switch (id) {
    case '4':
      if (urlName.includes('management')) {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&typeValue=${item.id}`
      } else {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).subtract(1, 'months').format('YYYY-MM')}&typeValue=${
          item.id
        }`
      }

      break
    case '9':
      if (urlName.includes('management')) {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}`
      } else {
        switch (item?.id) {
          case '10':
            newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
              item.id
            }?id=${urlObj?.id || 101}&date=${moment
              .utc(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))
              .format('YYYY-MM-DD')}~${moment.utc(new Date()).format('YYYY-MM-DD')}&typeValue=${item.id}`
            break
          case '11':
            newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
              item.id
            }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}&newId=all`
            break
          case '25':
            newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
              item.id
            }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY')}&typeValue=${item.id}`
            break
          default:
            break
        }
      }
      break
    case '12':
      if (item.id === '24') {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment.utc(new Date()).format('YYYY-MM')}`
      } else if (item.id === '13') {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment(new Date()).startOf('months').format('YYYY-MM-DD')}~${moment(new Date())
          .endOf('months')
          .format('YYYY-MM-DD')}&typeValue=${item.id}`
      } else if (item.id === '29') {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment(new Date()).subtract(2, 'months').format('YYYY-MM-DD')}~${moment(
          new Date(),
        ).format('YYYY-MM-DD')}&typeValue=${item.id}`
      } else {
        newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
          item.id
        }?id=${urlObj?.id || 101}&date=${moment
          .utc(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000))
          .format('YYYY-MM-DD')}~${moment.utc(new Date()).format('YYYY-MM-DD')}&typeValue=${item.id}`
      }
      break
    case '16':
      newHrefStr = `${urlName.substring(urlName?.length - 1, urlName?.length) === '/' ? urlName : urlName + '/'}${
        item.id
      }?id=${urlObj?.id || 101}&date=${moment
        .utc(new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000))
        .format('YYYY')}-12-31&typeValue=${item.id}`
      break
    default:
      break
  }
  return newHrefStr
}

export const getDesc = (missions: string) => {
  if (missions.length > 0) {
    return missions.replace(/\n/g, '<br/>')
  }
  return ''
}
