import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { arrObj } from '@/api'

const useStyles = makeStyles()(() => ({
  root: {
    height: '34vh',
    overflow: 'auto',
  },
  tableBody: {
    border: '1px solid #ccc',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableBody1: {
    border: '1px solid #ccc',
    borderTop: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkBigBox: {
    display: 'flex',
  },
  checkBox: {
    padding: 0,
    marginRight: 10,
  },
  btn: {
    background: '#3582E5',
    color: '#fff',
    fontSize: '2vh',
    padding: '2px 30px',
    '&:hover': {
      background: '#3582E5',
      color: '#fff',
    },
  },
}))

interface RowProps {
  urlSetting: any
  configJson: any
  urlSetArr: (obj: any) => void
}
const RowBox = (props: RowProps) => {
  const { urlSetting, configJson, urlSetArr } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const [arr, setArr] = useState<any>([])

  const getUrl = (arr: any) => {
    let newStr: any = ''
    arr?.map((item: any, i: number) => {
      if (item.children?.filter((items: any) => items.search === true)?.length > 0) {
        if (i === 0) {
          newStr = `${item.englishName},${
            item.children?.filter((items: any) => items.search === true)[0]?.englishName
          },001`
        } else {
          newStr =
            newStr +
            '&' +
            `${item.englishName},${item.children?.filter((items: any) => items.search === true)[0]?.englishName},001`
        }
      } else {
        if (item.search) {
          if (i === 0) {
            newStr = item.englishName
          } else {
            newStr = newStr + '&' + item.englishName
          }
        }
      }
    })
    if (newStr[0] === '&') {
      const reg2 = new RegExp('&')
      newStr = newStr.replace(reg2, '')
    }
    return `${id}?${newStr}`
  }

  useEffect(() => {
    if (urlSetting && id) {
      setArr(urlSetting[id])
    }
  }, [urlSetting])

  return (
    <>
      <div className={classes.root}>
        {arr?.map((item: any, i: number) => {
          return (
            <React.Fragment key={i}>
              <div
                onClick={() => {
                  if (item?.children?.length) {
                    const newOpenArr = arr
                    newOpenArr.splice(i, 1, { ...item, open: !item.open })
                    setArr([...newOpenArr])
                  }
                }}
                style={{ marginTop: i === 0 ? 0 : 10 }}
                className={classes.tableBody}
              >
                <div className={classes.checkBigBox}>
                  <Checkbox
                    className={classes.checkBox}
                    checked={item.search}
                    onClick={(event: any) => {
                      event.stopPropagation()
                      const newArr = arr
                      if (!event.target.checked) {
                        const newCheckedArr: any[] = []
                        item?.children.map((res: any) => {
                          newCheckedArr.push({ ...res, search: false })
                        })
                        newArr.splice(i, 1, { ...item, search: event.target.checked, children: [...newCheckedArr] })
                      } else {
                        newArr.splice(i, 1, { ...item, search: event.target.checked })
                      }
                      setArr([...newArr])
                    }}
                  />
                  <div>{item.chineseName}</div>
                </div>
                {item?.children?.length > 0 ? (
                  item.open ? (
                    <KeyboardArrowUpIcon style={{ color: '#000' }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: '#000' }} />
                  )
                ) : (
                  <></>
                )}
              </div>
              <Collapse in={item.open} timeout="auto" unmountOnExit>
                {item.children.map((historyRow: any, index: number) => {
                  return (
                    <div className={classes.tableBody1} key={index}>
                      <Checkbox
                        checked={historyRow.search}
                        className={classes.checkBox}
                        onClick={(event: any) => {
                          event.stopPropagation()
                          const newChildrenArr: any[] = []
                          item.children?.map((items: any, j: number) => {
                            if (index === j) {
                              newChildrenArr.push({ ...items, search: event.target.checked })
                            } else {
                              newChildrenArr.push({ ...items, search: false })
                            }
                          })
                          const newArr = arr
                          if (event.target.checked) {
                            if (!item.search) {
                              newArr.splice(i, 1, {
                                ...item,
                                search: event.target.checked,
                                children: [...newChildrenArr],
                              })
                            } else {
                              newArr.splice(i, 1, { ...item, children: [...newChildrenArr] })
                            }
                          } else {
                            newArr.splice(i, 1, { ...item, children: [...newChildrenArr] })
                          }

                          setArr([...newArr])
                        }}
                      />
                      <div>{historyRow.chineseName}</div>
                    </div>
                  )
                })}
              </Collapse>
            </React.Fragment>
          )
        })}
      </div>
      <div style={{ textAlign: 'end', marginTop: 10 }}>
        <Button
          onClick={() => {
            navigate(`/${getUrl(arr)}`)
            // @ts-ignore
            urlSetArr(arrObj(configJson, id))
          }}
          variant="contained"
          className={classes.btn}
        >
          确定
        </Button>
      </div>
    </>
  )
}

export default RowBox
