import React, { useEffect } from 'react'
import { getArrList, getPoiList, getViewPort } from '@/api'
import { IconLayer } from '@deck.gl/layers/typed'

interface BuildingBoxProps {
  map: any
  arr: any
  configJson: any
  getLayerArr: any
  allArr: any
  id: any
}

let timer: NodeJS.Timeout
function DeckGlIconBox(props: BuildingBoxProps) {
  const { map, arr, configJson, getLayerArr, allArr, id } = props

  const getIcon = () => {
    const newLayerArr: any = []
    arr?.map((res: any) => {
      getPoiList('deckGlIcon', res.toString(), getViewPort(map?.deck?.viewManager?._viewports[0]?.getBounds()))
        ?.then((success: any) => {
          if (res.toString() === 'heatIcon') {
            const layer = new IconLayer({
              id: `${res.toString()}-deckGlIcon-layer`,
              data: getArrList(success, res, configJson, id)?.features,
              pickable: true,
              getIcon: (d) => {
                return {
                  url: d.properties.iconUrl,
                  width: 128,
                  height: 128,
                  mask: d.properties.mask,
                }
              },
              parameters: {
                depthTest: true,
              },
              billboard:
                res.toString().includes('household') ||
                res.toString().includes('heatIcon') ||
                res.toString().includes('monitorIcon'),
              // @ts-ignore
              getPosition: (d) => [...d.geometry.coordinates, d.properties.height],
              getSize: (d) => d.properties.size,
              getColor: (d) => {
                if (d.properties.temperature <= 10) {
                  return d.properties.color
                } else if (d.properties.temperature > 10 && d.properties.temperature <= 20) {
                  return [255, 140, 0]
                } else if (d.properties.temperature > 20 && d.properties.temperature < 30) {
                  return [255, 69, 0]
                }
              },
              getAngle: (d) => d.properties.iconAngle || 0,
              sizeUnits: 'meters',
            })
            newLayerArr.push(layer)
          } else {
            const layer = new IconLayer({
              id: `${res.toString()}-deckGlIcon-layer`,
              data: getArrList(success, res, configJson, id)?.features,
              pickable: true,
              getIcon: (d) => {
                return {
                  url: d.properties.iconUrl,
                  width: 128,
                  height: 128,
                  mask: d.properties.mask,
                }
              },
              parameters: {
                depthTest: true,
              },
              billboard:
                res.toString().includes('household') ||
                res.toString().includes('heatIcon') ||
                res.toString().includes('monitorIcon'),
              // @ts-ignore
              getPosition: (d) => [...d.geometry.coordinates, d.properties.height],
              getSize: (d) => d.properties.size,
              getColor: (d) => d.properties.color,
              getAngle: (d) => d.properties.iconAngle || 0,
              sizeUnits: 'meters',
            })
            newLayerArr.push(layer)
          }
          if (newLayerArr?.length === arr?.length) {
            getLayerArr(newLayerArr, allArr)
          }
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    if (map) {
      getIcon()
      clearInterval(timer)
      timer = setInterval(() => {
        getIcon()
      }, 3000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [map, arr, allArr])

  return <></>
}

export default DeckGlIconBox
