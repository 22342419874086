import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    borderBottom: '1px solid rgba(0,0,0,0.6)',
    fontSize: '3vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
      padding: '1.3vh 0',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0.5vh 0.5vh',
    fontSize: '1.5vh',
    lineHeight: 1.15,
    border: '1px solid rgba(0,0,0,0.6)',
    borderTop: 0,
    borderRight: 0,
    '@media (max-width: 1280px)': {
      fontSize: '1.3vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  thBox: {
    lineHeight: '18px',
    minHeight: 18,
    '@media (max-width: 1280px)': {
      lineHeight: '12px',
      minHeight: 12,
    },
  },
  tableBox: {
    width: 1650,
    minWidth: 1650,
  },
  widthOne: {
    width: 'calc( 100% / 35 )',
  },
  widthTwo: {
    width: 'calc( 100% / 35 * 2 )',
  },
  widthThree: {
    width: 'calc( 100% / 35 * 3 )',
  },
  widthFour: {
    width: 'calc( 100% / 35 * 9 )',
  },
}))

interface OneTableBoxProps {
  arrObj: any
}

const OneTableBox = (props: OneTableBoxProps) => {
  const { classes } = useStyles()
  const { arrObj } = props
  const [newObj, setNewObj] = useState<any>('')

  useEffect(() => {
    setNewObj(arrObj)
  }, [arrObj])

  return (
    newObj && (
      <Table id="pdfTable" className={classes.tableBox} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={35} align="center">
              {newObj?.name}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ borderRight: '1px solid rgba(0,0,0,0.6)' }}>
          <StyledTableRow>
            <StyledTableCell className={classes.widthOne} rowSpan={3} align="center">
              <div className={classes.thBox}>序号</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} rowSpan={3} colSpan={2} align="center">
              <div className={classes.thBox}>小区</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={9} align="center">
              <div className={classes.thBox}>物业管理费收缴</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={9} align="center">
              <div className={classes.thBox}>租金收缴</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={9} align="center">
              <div className={classes.thBox}>商铺收缴</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} rowSpan={3} align="center">
              <div className={classes.thBox}>三项费用收缴合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} rowSpan={3} align="center">
              <div className={classes.thBox}>日期</div>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收旧欠</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收当年</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>预收明年</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收旧欠</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收当年</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>预收明年</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收旧欠</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>收当年</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
              <div className={classes.thBox}>预收明年</div>
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthOne} align="center">
              <div className={classes.thBox}>户数</div>
            </StyledTableCell>
            <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
              <div className={classes.thBox}>合计金额</div>
            </StyledTableCell>
          </StyledTableRow>

          {newObj?.arr?.map((item: any, i: any) => {
            const total =
              item?.manage?.oldDebtMoney +
              item?.manage?.thenMoney +
              item?.manage?.nextYearMoney +
              item?.shops?.oldDebtMoney +
              item?.shops?.thenMoney +
              item?.shops?.nextYearMoney +
              item?.rent?.oldDebtMoney +
              item?.rent?.thenMoney +
              item?.rent?.nextYearMoney
                ? (
                    item?.manage?.oldDebtMoney +
                    item?.manage?.thenMoney +
                    item?.manage?.nextYearMoney +
                    item?.shops?.oldDebtMoney +
                    item?.shops?.thenMoney +
                    item?.shops?.nextYearMoney +
                    item?.rent?.oldDebtMoney +
                    item?.rent?.thenMoney +
                    item?.rent?.nextYearMoney
                  ).toFixed(2)
                : ''
            return (
              <StyledTableRow key={i}>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{i + 1}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>{item?.name}</div>
                </StyledTableCell>

                {/*物业管理费*/}
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.manage?.oldDebtOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.manage?.oldDebtMoney ? item?.manage?.oldDebtMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.manage?.thenOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.manage?.thenMoney ? item?.manage?.thenMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.manage?.nextYearOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.manage?.nextYearMoney ? item?.manage?.nextYearMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>

                {/*租金*/}
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.shops?.oldDebtOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.shops?.oldDebtMoney ? item?.shops?.oldDebtMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.shops?.thenOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>{item?.shops?.thenMoney ? item?.shops?.thenMoney.toFixed(2) : ''}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.shops?.nextYearOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.shops?.nextYearMoney ? item?.shops?.nextYearMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>

                {/*商铺*/}
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.rent?.oldDebtOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.rent?.oldDebtMoney ? item?.rent?.oldDebtMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.rent?.thenOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>{item?.rent?.thenMoney ? item?.rent?.thenMoney.toFixed(2) : ''}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthOne} align="center">
                  <div className={classes.thBox}>{item?.rent?.nextYearOwnerCount}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>
                    {item?.rent?.nextYearMoney ? item?.rent?.nextYearMoney.toFixed(2) : ''}
                  </div>
                </StyledTableCell>

                <StyledTableCell className={classes.widthTwo} colSpan={2} align="center">
                  <div className={classes.thBox}>{total}</div>
                </StyledTableCell>
                <StyledTableCell className={classes.widthThree} colSpan={3} align="center">
                  <div className={classes.thBox}>{item?.weekEnd}</div>
                </StyledTableCell>
              </StyledTableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  )
}

export default OneTableBox
