import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { FormControl, MenuItem, Select } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { postRender, setBuilding, setCommunity, setHouses, setParkingSpace, setTemperature } from '@/api'
import { config } from '@/Constants'

const useStyles = makeStyles()(() => ({
  modalBox: {
    position: 'absolute',
    right: 87,
    width: '15%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    '&>div:nth-of-type(2)': {
      textAlign: 'end',
    },
  },
  btn: {
    background: '#3582E5',
    color: '#fff',
    fontSize: '2vh',
    padding: '2px 30px',
    '&:hover': {
      background: '#3582E5',
      color: '#fff',
    },
  },
  btn1: {
    background: '#fff',
    color: '#000',
    padding: 5,
    minWidth: 'auto',
    marginBottom: 5,
    '&:hover': {
      background: '#fff',
      color: '#000',
    },
    '&>svg': {
      width: 50,
      height: 50,
    },
  },
  inputModalBox: {
    color: '#000',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5vh',
    fontSize: '2.5vh',
    '&>div:nth-of-type(1)': {
      width: '6vw',
    },
    '&>div:nth-of-type(2)': {
      flex: 1,
      '&>div': {
        width: '100%',
      },
    },
  },
  iptModal: {
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  btnBox: {
    position: 'absolute',
    top: 5,
    right: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  iconBox: {
    position: 'absolute',
    top: -7,
    right: -25,
    width: 40,
    height: 40,
    color: '#fff',
  },
}))

interface ControlBoxProps {
  mapBox: any
  configJson: any
  id: any
}

const ControlBox = (props: ControlBoxProps) => {
  const { classes } = useStyles()
  const { mapBox, configJson, id } = props
  const [buildingObj, setBuildingObj] = useState<any>({
    geometry: {},
    properties: {
      color: [255, 255, 255],
      sourceType: 'building',
      renderType: configJson[id]['building']?.renderType,
    },
  })
  const [drawBoo, setDrawBoo] = useState(true)
  const [geometryObj, setGeometryObj] = useState<any>({})
  const [displayType, setDisplayType] = useState<any>('none')
  const [topNum, setTopNum] = useState(20)

  useEffect(() => {
    if (mapBox !== '' && displayType === 'inline-block' && drawBoo) {
      setDrawBoo(false)
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: true,
          polygon: true,
          line_string: true,
          trash: true,
        },
      })
      mapBox.addControl(draw, 'top-left')
      mapBox.on('draw.create', (e: any) => {
        setGeometryObj({ ...e.features[0]?.geometry })
      })
      mapBox.on('draw.update', (e: any) => {
        setGeometryObj({ ...e.features[0]?.geometry })
      })
      mapBox.on('draw.delete', () => {
        setGeometryObj({})
      })
    }
  }, [drawBoo, displayType, mapBox])

  const getDivBox = () => {
    let Div
    switch (buildingObj?.properties?.sourceType) {
      case 'building':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>楼号</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.buildingId}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, buildingId: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>共几层</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levels}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levels: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>每层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levelsHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levelsHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'community':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>图片名称</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.name}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, name: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>图片地址</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.height}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, imageUrl: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'household':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>楼号</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.buildingId}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, buildingId: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>每层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levelsHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levelsHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'parkingSpace':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            // @ts-ignore
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {/* @ts-ignore */}
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>车位ID</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.id}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, id: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'tree':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>名称</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.name}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, name: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'streetLight':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>名称</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.name}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, name: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>角度</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.angle}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, angle: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'heatIcon':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>楼号</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.buildingId}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, buildingId: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>温度</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.temperature}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, temperature: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>第几层</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levels}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levels: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>每层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levelsHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levelsHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      case 'monitorIcon':
        Div = (
          <div>
            <div className={classes.inputModalBox}>
              <div>类型</div>
              <div>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={JSON.stringify({
                      sourceType: buildingObj?.properties?.sourceType,
                      renderType: buildingObj?.properties?.renderType,
                    })}
                    onChange={(event: any) => {
                      setBuildingObj({
                        ...buildingObj,
                        properties: {
                          ...buildingObj?.properties,
                          sourceType: JSON.parse(event.target.value)?.sourceType,
                          renderType: JSON.parse(event.target.value)?.renderType,
                        },
                      })
                    }}
                  >
                    {Object.keys(configJson[id])?.map((item, i) => {
                      return (
                        <MenuItem
                          key={i}
                          value={JSON.stringify({
                            sourceType: item,
                            renderType: configJson[id][item]?.renderType,
                          })}
                        >
                          {configJson[id][item]?.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>小区名</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.smallWard}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, smallWard: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>楼号</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.buildingId}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, buildingId: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>名称</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.name}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, name: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>第几层</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levels}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levels: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>每层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.levelsHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, levelsHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.inputModalBox}>
              <div>底层高</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={buildingObj?.properties?.baseHeight}
                  onChange={(event: any) => {
                    setBuildingObj({
                      ...buildingObj,
                      properties: { ...buildingObj?.properties, baseHeight: event.target.value },
                    })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        )
        break
      default:
        Div = <></>
        break
    }

    return Div
  }

  return (
    <div>
      <div style={{ display: displayType, top: topNum }} className={classes.modalBox}>
        {getDivBox()}
        <div>
          <Button
            onClick={() => {
              switch (buildingObj?.properties?.sourceType) {
                case 'building':
                  if (geometryObj?.coordinates?.length > 0) {
                    setBuilding(Number(buildingObj?.properties.buildingId), {
                      properties: {
                        ...buildingObj?.properties,
                        height: Number(buildingObj?.properties.levels) * Number(buildingObj?.properties.levelsHeight),
                        levels: Number(buildingObj?.properties.levels),
                        levelsHeight: Number(buildingObj?.properties.levelsHeight),
                        buildingId: Number(buildingObj?.properties.buildingId),
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                      },
                      geometry: { ...geometryObj },
                    })
                      .then(() => {
                        window.location.reload()
                      })
                      .catch(console.error)
                  }
                  break
                case 'community':
                  if (geometryObj?.coordinates?.length > 0) {
                    setCommunity(Number(id), {
                      properties: {
                        ...buildingObj?.properties,
                        projectId: Number(id),
                      },
                      geometry: { ...geometryObj },
                    })
                      .then(() => {
                        window.location.reload()
                      })
                      .catch(console.error)
                  }
                  break
                case 'household':
                  if (geometryObj?.coordinates?.length === 8) {
                    setHouses(Number(buildingObj?.properties.buildingId), {
                      properties: {
                        ...buildingObj?.properties,
                        levelsHeight: Number(buildingObj?.properties.levelsHeight),
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        buildingId: Number(buildingObj?.properties.buildingId),
                        iconSize: Number(buildingObj?.properties.iconSize) || 20,
                        color: buildingObj?.properties.color || [0, 128, 0],
                        iconUrl: buildingObj?.properties.iconUrl || '',
                      },
                      geometry: {
                        ...geometryObj,
                        type: 'MultiPoint',
                      },
                    })
                      .then(() => {
                        window.location.reload()
                      })
                      .catch(console.error)
                  }
                  break
                case 'parkingSpace':
                  if (geometryObj?.coordinates?.length > 0) {
                    setParkingSpace(Number(buildingObj?.properties.id), {
                      properties: {
                        ...buildingObj?.properties,
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        iconSize: Number(buildingObj?.properties.iconSize) || 20,
                        color: buildingObj?.properties.color || [0, 128, 0],
                        iconUrl: buildingObj?.properties.iconUrl || '',
                        iconAngle: Number(buildingObj?.properties.iconAngle) || 0,
                      },
                      geometry: {
                        ...geometryObj,
                      },
                    }).catch(console.error)
                  }
                  break
                case 'tree':
                  if (geometryObj?.coordinates?.length > 0) {
                    postRender(`${config.url.API_URL}/api/poi`, {
                      properties: {
                        ...buildingObj?.properties,
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        iconSize: Number(buildingObj?.properties.iconSize) || 20,
                        color: buildingObj?.properties.color || [0, 128, 0],
                        iconUrl: buildingObj?.properties.iconUrl || '',
                      },
                      geometry: {
                        ...geometryObj,
                      },
                    }).catch(console.error)
                  }
                  break
                case 'streetLight':
                  if (geometryObj?.coordinates?.length > 0) {
                    postRender(`${config.url.API_URL}/api/poi`, {
                      properties: {
                        ...buildingObj?.properties,
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        iconSize: Number(buildingObj?.properties.iconSize) || 1.5,
                        color: buildingObj?.properties.color || [0, 128, 0],
                        iconUrl: buildingObj?.properties.iconUrl || '',
                        orientation:
                          buildingObj?.properties.angle && `${Number(buildingObj?.properties.angle)}` !== 'NaN'
                            ? [0, Number(buildingObj?.properties.angle), 90]
                            : [0, 0, 90],
                      },
                      geometry: {
                        ...geometryObj,
                      },
                    }).catch(console.error)
                  }
                  break
                case 'heatIcon':
                  if (geometryObj?.coordinates?.length > 0) {
                    setTemperature({
                      properties: {
                        ...buildingObj?.properties,
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        levelsHeight: Number(buildingObj?.properties.levelsHeight),
                        levels: Number(buildingObj?.properties.levels),
                        height:
                          Number(buildingObj?.properties.levelsHeight) * (Number(buildingObj?.properties.levels) - 1),
                        buildingId: Number(buildingObj?.properties.buildingId),
                        temperature: Number(buildingObj?.properties.temperature),
                      },
                      geometry: {
                        coordinates:
                          geometryObj?.type === 'Point' ? [geometryObj?.coordinates] : geometryObj?.coordinates,
                        type: 'MultiPoint',
                      },
                    })
                      .then(() => {
                        // window.location.reload()
                      })
                      .catch(console.error)
                  }
                  break
                case 'monitorIcon':
                  if (geometryObj?.coordinates?.length > 0) {
                    setTemperature({
                      properties: {
                        ...buildingObj?.properties,
                        baseHeight: Number(buildingObj?.properties.baseHeight),
                        levelsHeight: Number(buildingObj?.properties.levelsHeight),
                        levels: Number(buildingObj?.properties.levels),
                        height:
                          Number(buildingObj?.properties.levelsHeight) * (Number(buildingObj?.properties.levels) - 1),
                        buildingId: Number(buildingObj?.properties.buildingId),
                      },
                      geometry: {
                        coordinates:
                          geometryObj?.type === 'Point' ? [geometryObj?.coordinates] : geometryObj?.coordinates,
                        type: 'MultiPoint',
                      },
                    })
                      .then(() => {
                        // window.location.reload()
                      })
                      .catch(console.error)
                  }
                  break
                default:
                  break
              }
            }}
            variant="contained"
            className={classes.btn}
          >
            添加
          </Button>
        </div>
        <PlayArrowIcon className={classes.iconBox} />
      </div>
      <div className={classes.btnBox}>
        <Button
          onClick={() => {
            if (displayType === 'inline-block') {
              setTopNum(20)
              setBuildingObj({
                geometry: {},
                properties: {
                  ...buildingObj?.properties,
                  sourceType: 'building',
                  renderType: configJson[id]['building']?.renderType,
                },
              })
              setDisplayType('none')
            } else {
              setTopNum(20)
              setBuildingObj({
                geometry: {},
                properties: {
                  ...buildingObj?.properties,
                  sourceType: 'building',
                  renderType: configJson[id]['building']?.renderType,
                },
              })
              setDisplayType('inline-block')
            }
          }}
          variant="contained"
          className={classes.btn1}
        >
          <HomeIcon />
        </Button>
      </div>
    </div>
  )
}

export default ControlBox
