import React, { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { setOwnerBill } from '@/api'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles()(() => ({
  btnSmall: {
    position: 'relative',
    background: '#214162',
    color: '#fff',
    fontSize: '1.8vh',
    padding: '2px 20px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>span': {
      position: 'absolute',
      width: '3vh !important',
      height: '3vh !important',
      color: '#999',
    },
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    lineHeight: '30px',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
      lineHeight: '14px',
    },
  },
}))

interface CarListBtnBoxProps {
  ownerId: any
}

const CarListBtnBox = (props: CarListBtnBoxProps) => {
  const { ownerId } = props
  const { classes } = useStyles()
  const [synchronizeBoo, setSynchronizeBoo] = useState<any>(false)

  return (
    <Button
      disabled={synchronizeBoo}
      onClick={() => {
        !synchronizeBoo && setSynchronizeBoo(true)
        setOwnerBill('', '', ownerId)
          .then((res: any) => {
            if (res?.message === 'success') {
              setSynchronizeBoo(false)
            }
          })
          .catch(console.error)
      }}
      variant="contained"
      className={classes.btnSmall}
    >
      同步
      {synchronizeBoo && <CircularProgress />}
    </Button>
  )
}

export default CarListBtnBox
