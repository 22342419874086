import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import { FormControl, MenuItem, Select } from '@mui/material'
import { exportExcel, getParkingLotList, getQueryParamsObj, getYardDaily } from '@/api'
import { useNavigate } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import moment from 'moment'
import dayjs from 'dayjs'
import classNames from 'classnames'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '32px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    alignItems: 'center',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  fillButton: {
    color: '#fff',
    padding: '0.5vh',
    width: '3.5vh',
    height: '3.5vh',
    minWidth: '3.5vh !important',
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  },
  turnPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  paginationBox: {
    '&>ul>li>.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#c4c1c1',
      color: '#333',
    },
    '&>ul>li>button': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
    },
    '&>ul>li>button>svg': {
      fontSize: 45,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 35,
      },
    },
    '&>ul>li>div': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 26,
        height: 30,
        minWidth: 30,
      },
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '20%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '40%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '70%',
      width: '90%',
    },
    '@media (min-width: 1281px)': {
      justifyContent: 'space-between',
    },
  },
  selectBigBox: {
    width: '45%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '35%',
    },
  },
  selectBigBoxMargin: {
    marginBottom: 10,
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  tableBox: {
    overflow: 'auto',
    minWidth: 660,
  },
  selectBox: {
    width: '70%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  textField: {
    width: '65%',
  },
  underline: {
    '&::before,&::after': {
      display: 'none',
    },
  },
  dateInput: {
    textAlign: 'center',
    color: '#000',
    fontSize: 20,
    height: 36,
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    width: '80%',
    height: '80%',
    background: '#fff',
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dateBox: {
    height: '100%',
    width: '75%',
    '&>div': {
      height: '100%',
      padding: 0,
      '&>div': {
        height: '100%',
        minWidth: 'auto !important',
        '&>div': {
          height: '100%',
          '&>input': {
            padding: '0 10px',
            fontSize: '1.8vh',
          },
          '&>fieldset': {
            top: '-4px',
            border: '2px solid #000 !important',
          },
        },
      },
    },
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  totalBox: {
    display: 'flex',
    width: '100%',
    background: '#fff',
    borderRadius: ' 0 0 5px 5px',
    color: '#000',
    minWidth: 660,
    '&>div': {
      width: '21.6%',
      padding: '0.5vh',
      fontSize: '2vh',
      lineHeight: 1.5,
      fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
      fontWeight: 600,
      textAlign: 'right',
      '@media (max-width: 1280px)': {
        fontSize: '1.6vh',
      },
    },
  },
  iphoneBox: {
    height: '93%',
    '@media (max-width: 1280px)': {
      height: '80%',
      overflow: 'auto',
    },
  },
}))

interface DayReportBoxProps {
  divWidth: any
  urlName: any
}

const DayReportBox = (props: DayReportBoxProps) => {
  const { urlName, divWidth } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [paddingBoo, setPaddingBoo] = useState(false)
  const [selectValue, setSelectValue] = useState('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [arrList, setArrList] = useState<any>([])
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>('')
  const [arrExportList, setArrExportList] = useState<any>({})
  const [totalObj, setTotalObj] = useState<any>({
    monthlyRent: 0,
    offlineBooking: 0,
    offlineIncome: 0,
    onlineIncome: 0,
  })

  const tableBigBox = document.getElementById('tableBigBox')
  const totalBox = document.getElementById('totalBox')
  const tableBox = document.getElementById('tableBox')

  useEffect(() => {
    if (tableBigBox?.clientHeight && tableBox?.clientHeight && arrList?.length > 0) {
      if (tableBox?.clientHeight <= tableBigBox?.clientHeight) {
        !paddingBoo && setPaddingBoo(true)
      } else {
        paddingBoo && setPaddingBoo(false)
      }
    }
  }, [tableBigBox?.clientHeight, tableBox?.clientHeight, arrList])

  const headerArr = [
    {
      key: 0,
      name: '项目名',
      width: '17.6%',
    },
    {
      key: 1,
      name: '日期',
      width: '17.6%',
    },
    {
      key: 2,
      name: '小码应收',
      width: '21.6%',
    },
    {
      key: 3,
      name: '小码实收',
      width: '21.6%',
    },
    {
      key: 4,
      name: '大码实收',
      width: '21.6%',
    },
  ]

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:E${(res?.length || 0) + 1}`,
      '!ref': `A1:E${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '项目名' },
      B1: { t: 's', v: '日期' },
      C1: { t: 's', v: '小码应收' },
      D1: { t: 's', v: '小码实收' },
      E1: { t: 's', v: '大码实收' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.projectName || '' }
      arrObj[`B${i + 2}`] = { t: 's', v: item.date || '' }
      arrObj[`C${i + 2}`] = { t: 'n', v: item.offlineBooking || 0 }
      arrObj[`D${i + 2}`] = { t: 'n', v: item.offlineIncome || 0 }
      arrObj[`E${i + 2}`] = { t: 'n', v: item.onlineIncome || 0 }
    })
    setArrExportList(arrObj)
  }

  const getReportArr = (id: any, date: any) => {
    if (dateObj?.typeValue) {
      getYardDaily(id, date[0], date[1])
        .then((res: any) => {
          let monthlyRentNum: any = 0
          let offlineBookingNum: any = 0
          let offlineIncomeNum: any = 0
          let onlineIncomeNum: any = 0
          res?.map((item: any, i: any) => {
            monthlyRentNum += item.monthlyRent
            offlineBookingNum += item.offlineBooking
            offlineIncomeNum += item.offlineIncome
            onlineIncomeNum += item.onlineIncome

            if (i === res?.length - 1) {
              setTotalObj({
                monthlyRent: monthlyRentNum,
                offlineBooking: offlineBookingNum,
                offlineIncome: offlineIncomeNum,
                onlineIncome: onlineIncomeNum,
              })
            }
          })
          setArrList(res)
          exportObj(res)
        })
        .catch(console.error)
    }
  }

  const getParkingLotArr = () => {
    getParkingLotList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  useEffect(() => {
    setArrList([])
    setPaddingBoo(true)
    setTotalObj({
      monthlyRent: 0,
      offlineBooking: 0,
      onlineIncome: 0,
      offlineIncome: 0,
    })
    if (dateObj?.id) {
      setSelectValue(dateObj?.id)
    }
    if (dateObj?.date) {
      setDateValue(dateObj?.date?.split('~') || ['', ''])
    }
    if (dateObj?.id && dateObj?.date && dateObj?.typeValue === '10' && arrParkingLotList?.length > 0) {
      getReportArr(
        arrParkingLotList?.filter((item: any) => item.projectId === dateObj?.id)[0]?.projectId,
        dateObj?.date?.split('~') || ['', ''],
      )
    }
  }, [dateObj?.id, dateObj?.date, dateObj?.typeValue, arrParkingLotList])

  useEffect(() => {
    getParkingLotArr()
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {!urlName.includes('management') && (
            <div
              className={classNames(
                divWidth > 1280 ? [classes.selectBigBox] : [classes.selectBigBox, classes.selectBigBoxMargin],
              )}
            >
              <div className={classes.titleText}>车场： </div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.projectId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>时间： </div>
            <div className={classes.dateBox}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['SingleInputDateRangeField']}>
                  <DateRangePicker
                    disableFuture
                    format="YYYY/MM/DD"
                    minDate={dayjs(
                      moment.utc(new Date(new Date().getTime() - 60 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
                    )}
                    value={[dayjs(dateValue[0]), dayjs(dateValue[1])]}
                    onChange={(e: any) => {
                      setDateValue([dayjs(e[0]).format('YYYY-MM-DD'), dayjs(e[1]).format('YYYY-MM-DD')])
                    }}
                    slots={{ field: SingleInputDateRangeField }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              if (dateObj?.id === selectValue && dateObj?.date === `${dateValue[0]}~${dateValue[1]}`) {
                getReportArr(
                  arrParkingLotList?.filter((item: any) => item.projectId === selectValue)[0]?.projectId,
                  dateValue,
                )
              } else {
                navigate(`?id=${selectValue}&date=${dateValue[0]}~${dateValue[1]}&typeValue=${dateObj?.typeValue}`)
              }
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          {divWidth > 1280 && (
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                if (selectValue !== '' && dateValue !== '' && arrList?.length > 0) {
                  exportExcel(
                    arrExportList,
                    `${arrParkingLotList.filter((res: any) => res.id === selectValue)[0]?.name}临时车日报-${moment
                      .utc(new Date())
                      .format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              导出
            </Button>
          )}
        </div>
      </div>
      <div className={classes.iphoneBox}>
        <div
          id="tableBigBox"
          style={{
            maxHeight: `calc( 100% - ${totalBox?.clientHeight}px )`,
          }}
          className={classes.tableBox}
        >
          <Table id="tableBox" stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                {headerArr?.map((item: any) => {
                  return (
                    <StyledTableCell style={{ width: item.width }} key={item.key} align="right">
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {arrList?.map((item: any, i: number) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.projectName || ''}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.date || ''}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>
                        <span
                          onClick={() => {
                            navigate(`${urlName}3?id=${selectValue}&date=${item?.date}`)
                          }}
                          className={classes.thBoxHover}
                        >
                          {item.offlineBooking?.toFixed(2) || 0}
                        </span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.offlineIncome?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className={classes.thBox}>{item.onlineIncome?.toFixed(2) || 0}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div id="totalBox" style={{ paddingRight: paddingBoo ? 0 : 15 }} className={classes.totalBox}>
          <div style={{ width: '17.6%' }}>总计</div>
          <div style={{ width: '17.6%' }} />
          <div>{totalObj?.offlineBooking?.toFixed(2) || 0}</div>
          <div>{totalObj?.offlineIncome?.toFixed(2) || 0}</div>
          <div>{totalObj?.onlineIncome?.toFixed(2) || 0}</div>
        </div>
      </div>
    </div>
  )
}

export default DayReportBox
