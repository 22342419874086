import React, { useEffect } from 'react'
import { getPoiList, getViewPort } from '@/api'

interface ImageBoxProps {
  map: any
  arr: any
  deckMap?: any
  configJson: any
  id: any
}

let timer: NodeJS.Timeout
function MapBoxImageBox(props: ImageBoxProps) {
  const { map, arr, deckMap, configJson, id } = props

  const getImage = () => {
    let newLayer = map?.getStyle().layers
    newLayer?.splice(0, 134)
    newLayer = newLayer.filter((res: any) => res.id.indexOf('mapBoxImage') !== -1)
    newLayer?.map((item: any) => {
      if (arr?.mapBoxAllArr.indexOf(item?.id.split('-')[0]) === -1) {
        map.getMap().removeLayer(item.id)
        map.getMap().removeSource(item.source)
      }
    })
    arr?.mapBoxImageArr?.map((res: any) => {
      getPoiList(
        'mapBoxImage',
        res.toString(),
        getViewPort(
          deckMap?.deck?.viewManager?._viewports[0]?.getBounds() || [
            map?.getBounds()?._sw?.lng,
            map?.getBounds()?._sw?.lat,
            map?.getBounds()?._ne?.lng,
            map?.getBounds()?._ne?.lat,
          ],
        ),
      )
        .then((success: any) => {
          success?.features?.map((item: any) => {
            const newArr = item?.geometry?.coordinates[0]
            newArr.pop()
            // @ts-ignore
            if (!map.getSource(`${res.toString()}-mapBoxImage-source`)) {
              map.getMap().addSource(`${res.toString()}-mapBoxImage-source`, {
                type: 'image',
                url: item?.properties?.imageUrl,
                coordinates: newArr,
              })
              map.getMap().addLayer({
                id: `${res.toString()}-mapBoxImage-layer`,
                type: 'raster',
                source: `${res.toString()}-mapBoxImage-source`,
                paint: { 'raster-fade-duration': 0 },
              })
              if (
                map.getLayer(
                  `${arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]}-${
                    configJson[id][arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]]?.renderType
                  }-layer`,
                )
              ) {
                map
                  .getMap()
                  .moveLayer(
                    `${res.toString()}-mapBoxImage-layer`,
                    `${arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]}-${
                      configJson[id][arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]]?.renderType
                    }-layer`,
                  )
                // console.log(map?.getStyle().layers)
              }
            }
          })
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    if (map) {
      getImage()
      clearInterval(timer)
      timer = setInterval(() => {
        getImage()
      }, 3000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [map, arr])

  return <></>
}

export default MapBoxImageBox
