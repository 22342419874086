import React from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import { Collapse } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.7vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const useStyles = makeStyles()(() => ({
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '@media (max-width: 1280px)': {
        width: '2vh',
        height: '2vh',
      },
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
}))

interface UnconfirmedBillsCollapseBoxProps {
  openBoo: boolean
  allArr: any
}

const UnconfirmedBillsCollapseBox = (props: UnconfirmedBillsCollapseBoxProps) => {
  const { classes } = useStyles()
  const { openBoo, allArr } = props

  return (
    <Collapse in={openBoo} timeout={300} unmountOnExit>
      <Table stickyHeader aria-label="customized table">
        <TableBody>
          {allArr?.map((item: any, i: any) => {
            return (
              <TableRow key={i}>
                <StyledTableCell style={{ width: '7%' }} align="left" />
                <StyledTableCell style={{ width: '15.5%' }} align="left">
                  {item?.billLxName}
                </StyledTableCell>
                <StyledTableCell style={{ width: '6%' }} align="left">
                  {item?.receiptDate}
                </StyledTableCell>
                <StyledTableCell style={{ width: '13.5%' }} align="left">
                  {item?.receiptNumber}
                </StyledTableCell>
                <StyledTableCell style={{ width: '13.5%' }} align="left">
                  {item?.invoiceNumber}
                </StyledTableCell>
                <StyledTableCell style={{ width: '18%' }} align="left">
                  {item?.buyer}
                </StyledTableCell>
                <StyledTableCell style={{ width: '10.5%' }} align="left">
                  {item?.ownerName}
                </StyledTableCell>
                <StyledTableCell style={{ width: '8%' }} align="right">
                  <div className={classes.thBox}>{item?.amount?.toFixed(2) || 0}</div>
                </StyledTableCell>
                <StyledTableCell style={{ width: '8%' }} align="right">
                  <div className={classes.thBox}>{item?.tax?.toFixed(2) || 0}</div>
                </StyledTableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Collapse>
  )
}

export default UnconfirmedBillsCollapseBox
