import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import { getProjectList, getQueryParamsObj, getTemporaryVehicle, setCar } from '@/api'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import moment from 'moment/moment'
import Modal from '@mui/material/Modal'
import { FormControl, MenuItem, Select } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import classNames from 'classnames'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0.5vh',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    borderRadius: '5px 5px 0 0',
    '@media (max-width: 1280px)': {
      fontSize: '1.9vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '0 0.5vh',
    fontSize: '1.8vh',
    lineHeight: '34px',
    borderBottom: '1px solid rgba(224, 224, 224, 1) !important',
    '@media (max-width: 1280px)': {
      fontSize: '1.6vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
    color: '#000',
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '20%',
      justifyContent: 'space-between',
    },
  },
  titleHeight: {
    height: '7% !important',
    justifyContent: 'end !important',
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '80%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '80%',
    },
  },
  tableBox: {
    width: '100%',
    height: '93%',
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'auto',
    '@media (max-width: 1280px)': {
      height: '80%',
    },
  },
  tableBoxHeight: {
    height: '93% !important',
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    height: '38%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&>div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'column',
      '&>div': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
      },
    },
    '&>div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'end',
    },
  },
  selectBigBox: {
    width: '24%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '35%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  tipBox: {
    fontSize: '2.5vh',
    color: 'red',
  },
  iptModal: {
    height: '100%',
    width: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  selectBox: {
    width: '75%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        width: '3.5vh',
        height: '3.5vh',
        color: '#214162',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
      fontSize: '2.5vh',
    },
  },
  thBox: {
    lineHeight: 1.43,
  },
}))

interface TemporaryVehicleProps {
  urlName: any
  parkingJson: any
}

const TemporaryVehicle = (props: TemporaryVehicleProps) => {
  const { urlName, parkingJson } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const urlObj = getQueryParamsObj(window.location?.search)
  const [listArr, setListArr] = useState<any>([])
  const [carTypeValue, setCarTypeValue] = useState<any>(false)
  const [carObj, setCarObj] = useState<any>({ userName: '', plateNumber: '', comment: '' })
  const [checked, setChecked] = useState(false)
  const [selectValue, setSelectValue] = useState('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [open, setOpen] = useState<any>(false)

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const handleClose = () => {
    setOpen(false)
    setCarObj({ userName: '', plateNumber: '', comment: '' })
  }

  const communityHeaderArr = [
    {
      key: 1,
      name: '序号',
      width: '7%',
    },
    {
      key: 2,
      name: '车主',
      width: '20%',
    },
    {
      key: 3,
      name: '车牌',
      width: '12%',
    },
    {
      key: 4,
      name: '车型',
      width: '10%',
    },
    {
      key: 5,
      name: '起始日期',
      width: '15%',
    },
    {
      key: 6,
      name: '截止日期',
      width: '15%',
    },
    {
      key: 7,
      name: '备注',
      width: '20%',
    },
  ]

  const getList = () => {
    getTemporaryVehicle(selectValue || urlObj?.id, checked || false)
      .then((res: any) => {
        setListArr(res)
      })
      .catch(console.error)
  }

  useEffect(() => {
    getParkingLotArr()
  }, [])

  useEffect(() => {
    if (urlObj?.id) {
      getList()
      setSelectValue(urlObj?.id)
    }
  }, [urlObj?.id])

  return (
    <div className={classes.root}>
      <div
        className={classNames(urlName.includes('management') ? [classes.title, classes.titleHeight] : [classes.title])}
      >
        {!urlName.includes('management') && (
          <div className={classes.titleLeftBox}>
            <div className={classes.selectBigBox}>
              <div className={classes.titleText}>小区：</div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.id}>
                          {`${item.name}-${item.id}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className={classes.selectBigBox}>
              <FormGroup>
                <FormControlLabel
                  className={classes.checkBox}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(e: any) => {
                        setChecked(e.target.checked)
                      }}
                      color="default"
                    />
                  }
                  label="查看公务车"
                />
              </FormGroup>
            </div>
          </div>
        )}
        <div>
          {!urlName.includes('management') && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                navigate(`?id=${selectValue}`)
                getList()
              }}
              variant="contained"
              className={classes.btn}
            >
              查询
            </Button>
          )}
          <Button
            onClick={() => {
              setOpen(true)
            }}
            variant="contained"
            className={classes.btn}
          >
            添加
          </Button>
        </div>
      </div>
      <div
        className={classNames(
          urlName.includes('management') ? [classes.tableBox, classes.tableBoxHeight] : [classes.tableBox],
        )}
      >
        <div style={{ maxHeight: '100%', overflow: 'auto', minWidth: 1100 }}>
          <Table id="tableBox" stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                {communityHeaderArr?.map((item: any) => {
                  return (
                    <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                      {item.name}
                    </StyledTableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {listArr?.map((item: any, i: any) => {
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">{i + 1}</StyledTableCell>
                    <StyledTableCell align="left">{item?.userName}</StyledTableCell>
                    <StyledTableCell align="left">{item?.plateNumber}</StyledTableCell>
                    <StyledTableCell align="left">{item?.carTypeName}</StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.beginAt ? moment.utc(item?.beginAt).format('YYYY-MM-DD') : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.endAt ? moment.utc(item?.endAt).format('YYYY-MM-DD') : ''}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className={classes.thBox}>{item?.comment}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>
          <div>
            <div>
              <div className={classes.titleText} />
              <div className={classes.tipBox}>{`添加的临时车时效为${parkingJson?.temporaryDays || 3}天！`}</div>
            </div>
            <div>
              <div className={classes.titleText}>车主：</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={carObj?.userName}
                  onChange={(event: any) => {
                    setCarObj({ ...carObj, userName: event.target.value })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div>
              <div className={classes.titleText}>车牌：</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={carObj?.plateNumber}
                  onChange={(event: any) => {
                    setCarObj({ ...carObj, plateNumber: event.target.value })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div>
              <div className={classes.titleText}>备注：</div>
              <div>
                <TextField
                  className={classes.iptModal}
                  value={carObj?.comment}
                  onChange={(event: any) => {
                    setCarObj({ ...carObj, comment: event.target.value })
                  }}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div>
              <div className={classes.titleText}>车型：</div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        checked={!carTypeValue}
                        onChange={() => {
                          setCarTypeValue(false)
                        }}
                        color="default"
                      />
                    }
                    label="月租车"
                  />
                  <FormControlLabel
                    className={classes.checkBox}
                    control={
                      <Checkbox
                        checked={carTypeValue}
                        onChange={() => {
                          setCarTypeValue(true)
                        }}
                        color="default"
                      />
                    }
                    label="亲情车"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div>
            <Button
              onClick={() => {
                if (carObj?.userName !== '' && carObj?.plateNumber !== '') {
                  const newObj = {
                    ...carObj,
                    carTypeName: carTypeValue ? '亲情车' : '月租车',
                    projectId: selectValue || urlObj?.id,
                    beginAt: `${moment.utc(new Date()).format('YYYY-MM-DD')}T00:00:00`,
                    endAt: `${moment
                      .utc(new Date(new Date().getTime() + (parkingJson?.temporaryDays || 3) * 24 * 60 * 60 * 1000))
                      .format('YYYY-MM-DD')}T23:59:59`,
                  }
                  setCar(newObj)
                    .then((res: any) => {
                      if (res) {
                        handleClose()
                        if (selectValue !== urlObj?.id) {
                          navigate(`?id=${selectValue}`)
                        } else {
                          getList()
                        }
                      }
                    })
                    .catch(console.error)
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              确定
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TemporaryVehicle
