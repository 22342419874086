import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import { FormControl, MenuItem, Select } from '@mui/material'
import { getAuditList, getParkingLotList, getQueryParamsObj, getRender, postRender } from '@/api'
import { useNavigate } from 'react-router-dom'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import Modal from '@mui/material/Modal'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import SurveillanceVideo from '@/components/adminBox/surveillanceVideo'
import classNames from 'classnames'
import { styled } from '@mui/material/styles'

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '1.4vh',
  },
}))

const StyledTableCell = withStyles(TableCell, (theme) => ({
  head: {
    color: theme.palette.common.black,
    padding: '1.5vh 0',
    border: 0,
    fontSize: '2.2vh',
    fontWeight: 600,
    lineHeight: 1.15,
    '@media (max-width: 1280px)': {
      fontSize: '1.5vh',
    },
  },
  body: {
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: '1vh 0',
    fontSize: '1.8vh',
    lineHeight: 1.15,
    borderBottom: '1px solid rgba(238,238,238,0.3)',
    '@media (max-width: 1280px)': {
      fontSize: '1.4vh',
    },
  },
}))

const StyledTableRow = withStyles(TableRow, () => ({
  root: {
    // backgroundColor: '#31353E',
  },
}))

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
    '&>svg': {
      width: '3vh',
      height: '3vh',
    },
    '@media (max-width: 1280px)': {
      lineHeight: '18px',
    },
  },
  thBox1: {
    display: 'flex',
    alignItems: 'center',
    '&>svg': {
      width: '3vh',
      height: '3vh',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  fillButton: {
    color: '#fff',
    padding: '0.5vh',
    width: '3.5vh',
    height: '3.5vh',
    minWidth: '3.5vh !important',
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  },
  turnPage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  paginationBox: {
    '&>ul>li>.MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#c4c1c1',
      color: '#333',
    },
    '&>ul>li>button': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
    },
    '&>ul>li>button>svg': {
      fontSize: 45,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 35,
      },
    },
    '&>ul>li>div': {
      color: '#999',
      fontSize: 40,
      height: 48,
      minWidth: 48,
      padding: 0,
      '@media (max-width: 1280px)': {
        fontSize: 26,
        height: 30,
        minWidth: 30,
      },
    },
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '20%',
      justifyContent: 'space-between',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '40%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '70%',
      width: '80%',
    },
    '@media (min-width: 1281px)': {
      justifyContent: 'space-between',
    },
  },
  selectBigBox: {
    width: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '35%',
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  tableBox: {
    height: '93%',
    overflow: 'auto',
    '@media (max-width: 1280px)': {
      height: '80%',
    },
  },
  selectBox: {
    width: '65%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
    '&>div': {
      height: '100%',
      '&>fieldset': {
        height: '100%',
        border: 0,
      },
    },
  },
  textField: {
    width: '65%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
    '&>div': {
      height: '100%',
      '&>fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23) !important',
      },
    },
  },
  underline: {
    '&::before,&::after': {
      display: 'none',
    },
  },
  dateInput: {
    textAlign: 'center',
    color: '#000',
    fontSize: '2vh',
    padding: 0,
    border: '2px solid #000',
    borderRadius: 5,
    boxSizing: 'border-box',
    height: '100%',
  },
  selectBigBoxMargin: {
    marginBottom: 10,
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    width: '80%',
    height: '80%',
    background: '#fff',
  },
  thBoxHover: {
    color: '#4169e1',
    borderBottom: '2px solid #4169e1',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

interface ParkingLotFinanceProps {
  divWidth: any
  urlName: any
}

const ParkingLotFinance = (props: ParkingLotFinanceProps) => {
  const { divWidth, urlName } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [streamsList, setStreamsList] = useState<any>('')
  const [selectValue, setSelectValue] = useState('')
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const [arrAuditList, setArrAuditList] = useState<any>([])
  const dateObj = getQueryParamsObj(window.location?.search)
  const [dateValue, setDateValue] = useState<any>('')

  const headerArr = [
    {
      key: 0,
      name: '序号',
      width: '5%',
    },
    {
      key: 1,
      name: '类别',
      width: '10%',
    },
    {
      key: 2,
      name: '通道',
      width: '10%',
    },
    {
      key: 3,
      name: '车牌',
      width: '10%',
    },
    {
      key: 4,
      name: '进入时间',
      width: '12%',
    },
    {
      key: 5,
      name: '离开时间',
      width: '12%',
    },
    {
      key: 6,
      name: '应付金额',
      width: '8%',
    },
    {
      key: 7,
      name: '实付金额',
      width: '8%',
    },
    {
      key: 8,
      name: '备注',
      width: '15%',
    },
    {
      key: 9,
      name: '回看',
      width: '10%',
    },
  ]

  const iphoneHeaderArr = [
    {
      key: 1,
      name: '车牌',
      width: '20%',
    },
    {
      key: 2,
      name: '通道',
      width: '10%',
    },
    {
      key: 3,
      name: '进入时间',
      width: '27%',
    },
    {
      key: 4,
      name: '离开时间',
      width: '27%',
    },
    {
      key: 5,
      name: '应付金额',
      width: '16%',
    },
  ]

  const getParkingLotArr = () => {
    getParkingLotList()
      .then((res: any) => {
        setArrParkingLotList(res)
      })
      .catch(console.error)
  }

  const getAuditArr = (id: any, date: any) => {
    getAuditList(id, date)
      .then((res: any) => {
        setArrAuditList(res)
      })
      .catch(console.error)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    getParkingLotArr()
    getRender(`http://127.0.0.1:8083/streams`)
      .then((res: any) => {
        JSON.parse(res.data)?.payload && setStreamsList(Object.keys(JSON.parse(res.data)?.payload)[0])
      })
      .catch(console.error)
    if (dateObj?.id) {
      setSelectValue(dateObj?.id || '')
    }
    if (dateObj?.date) {
      setDateValue(dateObj?.date || '')
    }
    getAuditArr(dateObj?.id || '', dateObj?.date || '')
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          {!urlName.includes('management') && (
            <div
              className={classNames(
                divWidth > 1280 ? [classes.selectBigBox] : [classes.selectBigBox, classes.selectBigBoxMargin],
              )}
            >
              <div className={classes.titleText}>车场： </div>
              <div className={classes.selectBox}>
                <FormControl className={classes.iptModal} fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    value={arrParkingLotList?.length > 0 ? selectValue : ''}
                    onChange={(event: any) => {
                      setSelectValue(event.target.value)
                    }}
                  >
                    {arrParkingLotList?.map((item: any, i: number) => {
                      return (
                        <MenuItem key={i} value={item.projectId}>
                          {item.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          )}
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>时间： </div>
            <TextField
              id="date-start"
              name="dateStart"
              type="date"
              value={dateValue}
              className={classes.textField}
              onChange={(event: any) => {
                setDateValue(event.target.value)
              }}
              InputProps={{
                classes: {
                  input: classes.dateInput,
                  underline: classes.underline,
                },
              }}
            />
          </div>
        </div>
        <div>
          <Button
            onClick={() => {
              getAuditArr(selectValue, dateValue)
              navigate(`?id=${selectValue}&date=${dateValue}`)
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            onClick={() => {
              if (selectValue !== '' && dateValue !== '') {
                document.title = `${
                  arrParkingLotList.filter((res: any) => res.id === selectValue)[0]?.name
                }${dateValue}的账单`
                navigate(`/auditList?id=${dateObj?.id}&date=${dateValue}`)
              }
            }}
            variant="contained"
            className={classes.btn}
          >
            分享
          </Button>
        </div>
      </div>
      <div id="tableBox" className={classes.tableBox}>
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              {(divWidth <= 1280 ? iphoneHeaderArr : headerArr)?.map((item: any) => {
                return (
                  <StyledTableCell style={{ width: item.width }} key={item.key} align="left">
                    {item.name}
                  </StyledTableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {arrAuditList?.map((item: any, i: number) => {
              return divWidth <= 1280 ? (
                <StyledTableRow key={i}>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      <span
                        className={classes.thBoxHover}
                        onClick={() => {
                          const result = item?.plateNumber?.substring(
                            item?.plateNumber?.length - 3,
                            item?.plateNumber?.length,
                          )
                          navigate(`${urlName}1?id=${dateObj?.id}&result=${result}`)
                        }}
                      >
                        {item?.plateNumber}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.gateName ? item.gateName.substring(0, 2) : ''}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.enterTime ? moment.utc(item?.enterTime).format('YY-MM-DD HH:mm:ss') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.exitTime ? moment.utc(item?.exitTime).format('YY-MM-DD HH:mm:ss') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.billAmount}</div>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow key={i}>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{i + 1}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.category}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item.gateName ? item.gateName : ''}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      <span
                        className={classes.thBoxHover}
                        onClick={() => {
                          const result = item?.plateNumber?.substring(
                            item?.plateNumber?.length - 3,
                            item?.plateNumber?.length,
                          )
                          navigate(`${urlName}1?id=${dateObj?.id}&result=${result}`)
                        }}
                      >
                        {item?.plateNumber}
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.enterTime ? moment.utc(item?.enterTime).format('YY-MM-DD HH:mm:ss') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>
                      {item?.exitTime ? moment.utc(item?.exitTime).format('YY-MM-DD HH:mm:ss') : ''}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.billAmount}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={classes.thBox}>{item?.actualAmount}</div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <BootstrapTooltip title={item?.remark} placement="right">
                      <div style={{ width: '14.5vw' }} className={classes.thBox}>
                        {item?.remark}
                      </div>
                    </BootstrapTooltip>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {item?.videoUrl !== null ? (
                      <div className={classes.thBox1}>
                        {item?.videoUrl.split(',')?.map((items: any, i: any) => {
                          return (
                            <OndemandVideoIcon
                              style={{
                                marginRight: i + 1 === item?.videoUrl.split(',')?.length ? 0 : 5,
                              }}
                              key={i}
                              onClick={() => {
                                streamsList !== '' &&
                                  getRender(`http://demo:demo@127.0.0.1:8083/stream/${streamsList}/delete`).catch(
                                    console.error,
                                  )
                                const num = Math.floor(Math.random() * 10000000)
                                setStreamsList(num)
                                postRender(`http://demo:demo@127.0.0.1:8083/stream/${num}/add`, {
                                  channels: {
                                    '0': {
                                      url: items,
                                    },
                                  },
                                })
                                handleOpen()
                              }}
                            />
                          )
                        })}
                      </div>
                    ) : (
                      <div className={classes.thBox1} />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div className={classes.modalSmallBox}>
          <SurveillanceVideo uuid={streamsList} />
        </div>
      </Modal>
    </div>
  )
}

export default ParkingLotFinance
