import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import FormGroupBox from '@/components/adminBox/invoicingListBox/invoicingCollapseBox/collapseChildBox/formGroupBox'
import TextFieldBox from '@/components/adminBox/invoicingListBox/invoicingCollapseBox/collapseChildBox/textFieldBox'

const useStyles = makeStyles()(() => ({
  operateBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
  },
}))

interface BigBoxProps {
  label: any
  receiptNumber: any
  payTime: any
}

const BigBox = (props: BigBoxProps) => {
  const { classes } = useStyles()
  const { label, receiptNumber, payTime } = props
  const [timeBoo, setTimeBoo] = useState(false)

  useEffect(() => {
    setTimeBoo(label === '已收款')
  }, [])

  const getTimeBoo = (boo: any) => {
    setTimeBoo(boo)
  }

  return (
    <div className={classes.operateBox}>
      <FormGroupBox timeBoo={timeBoo} receiptNumber={receiptNumber} />
      <TextFieldBox timeBoo={timeBoo} receiptNumber={receiptNumber} getTimeBoo={getTimeBoo} payTime={payTime} />
    </div>
  )
}

export default BigBox
