import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment'
import { FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material'
import TextField from '@mui/material/TextField'

const useStyles = makeStyles()(() => ({
  content1: {
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    fontSize: '1.5vh',
    padding: 10,
  },
  contentSmallBox: {
    display: 'flex',
    marginBottom: 5,
    '&>div': {
      display: 'flex',
      width: '50%',
      '&>div:nth-of-type(1)': {
        width: '50%',
      },
    },
  },
  remarkBox: {
    display: 'flex',
    marginBottom: 5,
    '&>div:nth-of-type(1)': {
      width: '25%',
    },
    '&>div:nth-of-type(2)': {
      width: '75%',
    },
  },
  remarkBox1: {
    display: 'flex',
    marginBottom: 5,
    '&>div': {
      width: '100%',
    },
  },
  remarkBox2: {
    display: 'flex',
    marginBottom: 5,
    '&>div:nth-of-type(1)': {
      width: '20%',
    },
    '&>div:nth-of-type(2)': {
      width: '15%',
      marginLeft: 5,
      display: 'flex',
      alignItems: 'center',
    },
  },
  radioBox: {
    height: '100%',
    fontSize: '1.5vh',
    '&>div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '&>label': {
        margin: 0,
        width: '45%',
        '&>span:nth-of-type(1)': {
          padding: '0 3px',
          '&>span>svg': {
            width: '2.5vh',
            height: '2.5vh',
          },
        },
        '&>span:nth-of-type(2)': {
          fontSize: '1.5vh',
        },
      },
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      fontSize: '1.5vh',
      background: 'transparent',
      padding: '15px 10px 5px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
        height: '2.7vh',
      },
      '&>fieldset': {
        height: '100%',
        borderColor: '#000 !important',
        borderWidth: '2px  !important',
        top: 0,
        '&>legend': {
          fontSize: '1em',
        },
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>label': {
      top: 5,
      color: '#000 !important',
    },
  },
}))

interface ContentBoxProps {
  iptType: boolean
  item: any
  numId: any
  arrList: any
  setNewArrList: (arr: any) => void
  getDisabledValue: () => void
  getIptBoo: () => void
}

const ContentBox = (props: ContentBoxProps) => {
  const { item, numId, arrList, setNewArrList, getDisabledValue, iptType, getIptBoo } = props
  const { classes } = useStyles()
  const [iptOpen, setIptOpen] = useState<any>(false)
  const [amountIptOpen, setAmountIptOpen] = useState<any>(false)
  // const [tipBoo, setTipBoo] = useState(false)
  const [divOpen, setDivOpen] = useState<any>(false)

  const selectArr = ['扫小码(大码异常)', '扫小码(亲情车)', '免费(月租车)', '免费(特殊车辆)', `免费(其他)`]

  useEffect(() => {
    if (iptType) {
      setIptOpen(false)
      setAmountIptOpen(false)
      getIptBoo()
    }
  }, [iptType])

  useEffect(() => {
    if (item?.remark.split(':')[0] !== '') {
      setIptOpen(true)
    }

    if (item?.remark.split(':')[0] === '扫小码(大码异常)' || item?.remark.split(':')[0] === '扫小码(亲情车)') {
      setAmountIptOpen(true)
    }
  }, [item])

  return (
    <div className={classes.content1}>
      <div className={classes.contentSmallBox}>
        <div>
          <div>车牌：</div>
          <div>{item.plateNumber}</div>
        </div>
        <div>
          <div>通道：</div>
          <div>{item.gateName.substring(0, 2)}</div>
        </div>
      </div>
      <div className={classes.contentSmallBox}>
        <div>
          <div>离开时间：</div>
          <div>{item.exitTime ? moment.utc(item.exitTime).format('HH:mm:ss') : ''}</div>
        </div>
        <div>
          <div>应收/实收：</div>
          <div>{`${item.billAmount}/${item.actualAmount} 元`}</div>
        </div>
      </div>
      <div className={classes.contentSmallBox}>
        <div>
          <div>按键：</div>
          <div>{item.category}</div>
        </div>
        <div />
      </div>
      <div className={classes.remarkBox1}>
        <div>
          <FormControl className={classes.radioBox} fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={item?.remark.split(':')[0]}
              onChange={(event: any) => {
                if (event.target.value !== '') {
                  setIptOpen(true)
                } else {
                  iptOpen && setIptOpen(false)
                }
                if (event.target.value === '扫小码(大码异常)' || event.target.value === '扫小码(亲情车)') {
                  setAmountIptOpen(true)
                } else {
                  amountIptOpen && setAmountIptOpen(false)
                }
                // if (event.target.value === '扫小码(大码异常)' || event.target.value === '扫小码(亲情车)') {
                //   setTipBoo(true)
                // } else {
                //   tipBoo && setTipBoo(false)
                // }
                divOpen && setDivOpen(false)
                const arrS = arrList
                if (event.target.value.includes('免费')) {
                  arrS.splice(numId, 1, {
                    ...item,
                    remark: `${event.target.value}:`,
                    actualAmount: 0,
                  })
                } else {
                  arrS.splice(numId, 1, {
                    ...item,
                    remark: `${event.target.value}:`,
                  })
                }
                setNewArrList(arrS)
                getDisabledValue()
              }}
            >
              {selectArr?.map((item: any, i: number) => {
                return <FormControlLabel key={i} value={item} control={<Radio />} label={item} />
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {iptOpen && (
        <div className={classes.remarkBox1}>
          <TextField
            label={item?.remark.split(':')[0]}
            focused
            className={classes.iptModal}
            value={item?.remark.split(':')[1]}
            onChange={(event: any) => {
              divOpen && setDivOpen(false)
              const arrS = arrList
              arrS.splice(numId, 1, {
                ...item,
                remark: `${item?.remark.split(':')[0]}:${event.target.value}`,
              })
              setNewArrList(arrS)
              getDisabledValue()
            }}
            id="outlined-basic"
            variant="outlined"
          />
        </div>
      )}
      {amountIptOpen && (
        <div className={classes.remarkBox2}>
          <TextField
            label="金额"
            className={classes.iptModal}
            value={item.actualAmount}
            onChange={(event: any) => {
              divOpen && setDivOpen(false)
              const arrS = arrList
              arrS.splice(numId, 1, {
                ...item,
                actualAmount: Number(event.target.value),
              })
              setNewArrList(arrS)
              getDisabledValue()
            }}
            id="outlined-basic"
            variant="outlined"
          />
          <div>元</div>
        </div>
      )}
      {/*{tipBoo && <div style={{ textAlign: 'center', color: 'rgb(211, 47, 47)' }}>请扫大码！</div>}*/}
    </div>
  )
}

export default ContentBox
