import React from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapBoxGeoJSONBox from '@/components/baseMap/mapBoxGeoJSONBox'
import MapBoxImageBox from '@/components/baseMap/mapBoxImageBox'

interface BaseMapBoxProps {
  allArr: any
  configJson: any
  map: any
  deckMap: any
  projectsObj: any
  id: any
}

const MapBoxLayerBox = (props: BaseMapBoxProps) => {
  const { allArr, configJson, map, deckMap, id } = props

  return (
    <>
      <MapBoxGeoJSONBox id={id} configJson={configJson} arr={allArr} map={map} deckMap={deckMap} />
      <MapBoxImageBox id={id} arr={allArr} map={map} deckMap={deckMap} configJson={configJson} />
    </>
  )
}

export default MapBoxLayerBox
