import React, { useEffect, useState } from 'react'
import DeckGL from '@deck.gl/react/typed'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useRef } from 'react'
import Map from 'react-map-gl'
import DeckGlLayerBox from '@/components/baseMap/deckGlLayerBox'
import MapBoxLayerBox from '@/components/baseMap/mapBoxLayerBox'
import LoadingBox from '@/components/baseMap/loadingBox'

interface BaseMapBoxProps {
  allArr: any
  configJson: any
  projectsObj: any
  id: any
}

const BaseMapBox = (props: BaseMapBoxProps) => {
  const { allArr, configJson, projectsObj, id } = props
  const deckRef = useRef()
  const mapRef = useRef()
  const [mapBoxBoo, setMapBoxBoo] = useState<any>(false)
  const [viewStateObj, setViewStateObj] = useState<any>({
    longitude: 121.5,
    latitude: 31.2603,
    zoom: 16.5,
    pitch: 45,
  })

  const setLoad = () => {
    setMapBoxBoo(true)
  }

  useEffect(() => {
    if (projectsObj !== '') {
      if (projectsObj?.longitude && projectsObj?.latitude && projectsObj?.zoom && projectsObj?.pitch) {
        setViewStateObj({
          longitude: projectsObj?.longitude,
          latitude: projectsObj?.latitude,
          zoom: projectsObj?.zoom,
          pitch: projectsObj?.pitch,
        })
      }
    }
  }, [projectsObj])

  return (
    <DeckGL
      initialViewState={viewStateObj}
      // @ts-ignore
      ref={deckRef}
      controller={true}
    >
      <Map
        mapboxAccessToken="pk.eyJ1IjoiZm94em9vbCIsImEiOiJjanJidmM1bXAwNmE0M3lwM214b3Q1bGxmIn0.vZ1amAfCBSYzN5W1uXn_lw"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onLoad={setLoad}
        // @ts-ignore
        ref={mapRef}
      >
        {mapBoxBoo && (
          <MapBoxLayerBox
            projectsObj={projectsObj}
            deckMap={deckRef.current}
            configJson={configJson}
            id={id}
            allArr={allArr}
            map={mapRef.current}
          />
        )}
      </Map>
      {/*{mapBoxBoo && (*/}
      {/*  <DeckPointLightBox id={id} configJson={configJson} arr={allArr?.deckGlScenegraphArr} map={deckRef.current} />*/}
      {/*)}*/}
      {!mapBoxBoo && <LoadingBox />}
      {mapBoxBoo && (
        <DeckGlLayerBox
          projectsObj={projectsObj}
          id={id}
          configJson={configJson}
          allArr={allArr}
          map={deckRef.current}
        />
      )}
    </DeckGL>
  )
}

export default BaseMapBox
