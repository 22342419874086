import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import {
  deletePublicWelfare,
  exportExcel,
  getProjectList,
  getPublicWelfare,
  getQueryParamsObj,
  tabExportExcel,
} from '@/api'
import moment from 'moment'
import { Collapse, FormControl, MenuItem, Select } from '@mui/material'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import '@/font/simhei-normal'
import TwoTableBox from '@/components/adminBox/publicWelfareBox/twoTableBox'
import OneTableBox from '@/components/adminBox/publicWelfareBox/oneTableBox'
import LoadingBox from '@/components/baseMap/loadingBox'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const useStyles = makeStyles()(() => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 15,
  },
  title: {
    padding: '10px 0',
    width: '100%',
    height: '7%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '30%',
    },
  },
  titleLeftBox: {
    display: 'flex',
    color: '#000',
    height: '100%',
    width: '60%',
    '@media (max-width: 1280px)': {
      flexDirection: 'column',
      height: '70%',
      width: '100%',
    },
  },
  selectBigBox: {
    width: '26%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    '@media (max-width: 1280px)': {
      width: '100%',
      height: '22%',
      marginRight: 0,
      marginBottom: 10,
    },
  },
  titleText: {
    width: '8vh',
    fontSize: '2.5vh',
    fontWeight: 600,
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
        height: '100%',
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  selectBox: {
    width: '65%',
    height: '100%',
    '@media (max-width: 1280px)': {
      width: '22vh',
    },
  },
  tableBox: {
    height: '93%',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    '@media (max-width: 1280px)': {
      height: '70%',
    },
  },
  btn: {
    marginLeft: 15,
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
      cursor: 'pointer',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  btn1: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 15px',
    height: 40,
    borderRadius: 5,
    border: 0,
    width: 100,
    '&:hover': {
      background: '#214162',
      color: '#fff',
      cursor: 'pointer',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  btnDis: {
    color: 'rgba(0, 0, 0, 0.26)',
    background: 'rgba(0, 0, 0, 0.12)',
    boxShadow: 'none',
    fontSize: '2.5vh',
    padding: '2px 15px',
    height: 40,
    borderRadius: 5,
    border: 0,
    width: 100,
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
      background: 'rgba(0, 0, 0, 0.12)',
    },
    '@media (max-width: 1280px)': {
      fontSize: '2vh',
    },
  },
  collapseBox: {
    position: 'absolute',
    right: 0,
    top: 'calc( 100% + 10px )',
    '&>div>div': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    position: 'relative',
    width: '15%',
    height: '16%',
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&>form': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
  },
  iptBox: {
    color: '#000',
    border: '2px solid #000',
    fontSize: '2vh',
    background: 'transparent',
    padding: '6px 10px',
    borderRadius: 5,
    width: '100%',
    height: 40,
  },
  iframeBox: {
    position: 'absolute',
    border: 0,
    height: '28%',
    top: 50,
    width: '80%',
    overflow: 'hidden',
  },
  formBtnBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  uploadBox: {
    color: 'red',
  },
  checkBox: {
    margin: 0,
    '&>span:nth-of-type(1)': {
      padding: 0,
      paddingRight: 5,
      '&>svg': {
        color: '#214162',
      },
    },
    '&>span:nth-of-type(2)': {
      color: '#000',
    },
  },
  checkBigBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const PublicWelfareBox = () => {
  const { classes } = useStyles()
  const [loadingBoo, setLoadingBoo] = useState<any>(true)
  const [arrList, setArrList] = useState<any>('')
  const [tableNum, setTableNum] = useState('')
  const [open, setOpen] = useState<any>(false)
  const [checked, setChecked] = useState(true)
  const [selectValue, setSelectValue] = useState('')
  const [arrExportList, setArrExportList] = useState<any>({})
  const [yearArr, setYearArr] = useState<any>([])
  const [yearSelectValue, setYearSelectValue] = useState(Number(moment.utc(new Date()).format('YYYY')))
  const [quarterSelectValue, setQuarterSelectValue] = useState(1)
  const [arrParkingLotList, setArrParkingLotList] = useState<any>([])
  const urlObj = getQueryParamsObj(window.location?.search)
  const navigate = useNavigate()

  const quarterArr: any = [
    { key: 1, name: '第一季度' },
    { key: 2, name: '第二季度' },
    { key: 3, name: '第三季度' },
    { key: 4, name: '第四季度' },
  ]

  const quarterObj: any = {
    1: '1-3月',
    2: '4-6月',
    3: '7-9月',
    4: '10-12月',
  }

  const exportObj = (res: any) => {
    let arrObj: any = {}
    arrObj = {
      '!cols': [],
      '!fullref': `A1:D${(res?.length || 0) + 1}`,
      '!ref': `A1:D${(res?.length || 0) + 1}`,
      '!rows': [],
      A1: { t: 's', v: '项目名' },
      B1: { t: 's', v: '金额' },
      C1: { t: 's', v: '收入' },
      D1: { t: 's', v: '支出' },
    }
    res?.forEach((item: any, i: number) => {
      arrObj[`A${i + 2}`] = { t: 's', v: item.summary || '' }
      arrObj[`B${i + 2}`] = { t: 'n', v: item.originDebitAmount || 0 }
      arrObj[`C${i + 2}`] = { t: 'n', v: item.debitAmount || 0 }
      arrObj[`D${i + 2}`] = { t: 'n', v: item.creditAmount || 0 }
    })
    setArrExportList(arrObj)
  }

  const getList = (name: any, year: any, quarter: any) => {
    setTableNum(selectValue || urlObj?.id)
    getPublicWelfare(selectValue || urlObj?.id, yearSelectValue, quarterSelectValue)
      .then((res: any) => {
        const incomeArr: any[] = []
        const expenditureArr: any[] = []
        res?.payments?.map((item: any) => {
          if (item?.debitAmount !== 0) {
            incomeArr.push(item)
          } else if (item?.creditAmount !== 0) {
            expenditureArr.push(item)
          }
        })
        exportObj(res?.payments)
        setLoadingBoo(false)
        console.log({
          name,
          year,
          quarter,
          currentQuarterAmount: res?.currentQuarterAmount,
          lastQuarterAmount: res?.lastQuarterAmount,
          currentQuarterAdjustment: res?.currentQuarterAdjustment,
          income: incomeArr,
          expenditure: expenditureArr,
          remark: res?.remark,
        })
        setArrList({
          name,
          year,
          quarter,
          currentQuarterAmount: res?.currentQuarterAmount,
          lastQuarterAmount: res?.lastQuarterAmount,
          currentQuarterAdjustment: res?.currentQuarterAdjustment,
          income: incomeArr,
          expenditure: expenditureArr,
          remark: res?.remark,
        })
      })
      .catch(console.error)
  }

  useEffect(() => {
    const newArr = [{ key: '0', name: 2023 }]
    for (let i = 1; i <= Number(moment.utc(new Date()).format('YYYY')) - 2023; i++) {
      newArr.push({ key: `${i}`, name: 2023 + i })
    }
    setYearArr([...newArr])
  }, [])

  const getParkingLotArr = () => {
    getProjectList()
      .then((res: any) => {
        setArrParkingLotList(res)
        getList(
          res?.filter((item: any) => item.id === urlObj?.id)[0]?.name,
          yearSelectValue,
          urlObj?.id === '113'
            ? quarterArr.filter((item: any) => item.key === quarterSelectValue)[0]?.name
            : quarterObj[quarterSelectValue],
        )
      })
      .catch(console.error)
  }

  useEffect(() => {
    getParkingLotArr()
    if (urlObj?.id) {
      setSelectValue(urlObj?.id || '')
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <div className={classes.titleLeftBox}>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>小区：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={arrParkingLotList?.length > 0 ? selectValue : ''}
                  onChange={(event: any) => {
                    setSelectValue(event.target.value)
                  }}
                >
                  {arrParkingLotList?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.id}>
                        {`${item.name}-${item.id}`}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>年度：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={yearArr?.length > 0 ? yearSelectValue : ''}
                  onChange={(event: any) => {
                    setYearSelectValue(event.target.value)
                  }}
                >
                  {yearArr?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.name}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.selectBigBox}>
            <div className={classes.titleText}>季度：</div>
            <div className={classes.selectBox}>
              <FormControl className={classes.iptModal} fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={quarterSelectValue}
                  onChange={(event: any) => {
                    setQuarterSelectValue(event.target.value)
                  }}
                >
                  {quarterArr?.map((item: any, i: number) => {
                    return (
                      <MenuItem key={i} value={item.key}>
                        {item.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.checkBigBox}>
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e: any) => {
                    e.target.checked && setChecked(e.target.checked)
                  }}
                  color="default"
                />
              }
              label="单页"
            />
            <FormControlLabel
              className={classes.checkBox}
              control={
                <Checkbox
                  checked={!checked}
                  onChange={(e: any) => {
                    e.target.checked && setChecked(!e.target.checked)
                  }}
                  color="default"
                />
              }
              label="分页"
            />
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Button
            style={{ marginLeft: 0 }}
            onClick={() => {
              navigate(`?id=${selectValue}`)
              setLoadingBoo(true)
              setTableNum(selectValue)
              getList(
                arrParkingLotList?.filter((res: any) => res.id === selectValue)[0]?.name,
                yearSelectValue,
                selectValue === '113'
                  ? quarterArr.filter((res: any) => res.key === quarterSelectValue)[0]?.name
                  : quarterObj[quarterSelectValue],
              )
            }}
            variant="contained"
            className={classes.btn}
          >
            查询
          </Button>
          <Button
            onClick={() => {
              deletePublicWelfare().catch(console.error)
            }}
            variant="contained"
            className={classes.btn}
          >
            清缓
          </Button>
          <Button
            onClick={() => {
              setOpen(!open)
            }}
            variant="contained"
            className={classes.btn}
          >
            导出
          </Button>
          <Collapse className={classes.collapseBox} in={open} timeout="auto" unmountOnExit>
            <Button
              style={{ padding: '2px 10px', marginLeft: 0, marginBottom: 10 }}
              onClick={() => {
                if (arrList !== '') {
                  // @ts-ignore
                  html2canvas(pdfTable, { scale: 2, dpi: 450 }).then((canvas: any) => {
                    if (!checked) {
                      const pdf = new jsPDF('p', 'mm', 'a4') //A4纸，纵向
                      const ctx = canvas.getContext('2d')
                      const a4w = 190
                      const a4h = 257 //A4大小，210mm x 1mm，四边各保留20mm的边距，显示区域190x277
                      const imgHeight = Math.floor((a4h * canvas.width) / a4w) //按A4显示比例换算一页图像的像素高度
                      let renderedHeight = 0

                      while (renderedHeight < canvas.height) {
                        const page: any = document.createElement('canvas')
                        page.width = canvas.width
                        page.height = Math.min(imgHeight, canvas.height - renderedHeight) //可能内容不足一页

                        //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
                        page
                          ?.getContext('2d')
                          .putImageData(
                            ctx?.getImageData(
                              0,
                              renderedHeight,
                              canvas.width,
                              Math.min(imgHeight, canvas.height - renderedHeight),
                            ),
                            0,
                            0,
                          )
                        pdf.addImage(
                          page.toDataURL('image/jpeg', 1.0),
                          'JPEG',
                          10,
                          10,
                          a4w,
                          Math.min(a4h, (a4w * page.height) / page.width),
                        ) //添加图像到页面，保留10mm边距

                        renderedHeight += imgHeight
                        if (renderedHeight < canvas.height) pdf.addPage() //如果后面还有内容，添加一个空页
                      }

                      pdf.setFont('simhei')
                      pdf.save(
                        `${arrList?.name}${arrList?.quarter}公益性收支明细-${moment
                          .utc(new Date())
                          .format('YYYY-MM-DD')}.pdf`,
                      )
                    } else {
                      const contentWidth = canvas.width
                      const contentHeight = canvas.height

                      /* 导出不分页处理 */
                      const pageData = canvas.toDataURL('image/jpeg', 1.0)

                      const pdfWidth = ((contentWidth + 10) / 2) * 0.75
                      const pdfHeight = ((contentHeight + 200) / 2) * 0.75 // 500为底部留白

                      const imgWidth = pdfWidth
                      const imgHeight = (contentHeight / 2) * 0.75 // 内容图片这里不需要留白的距离

                      // @ts-ignore
                      const pdf = new jsPDF('', 'pt', [pdfWidth + 50, pdfHeight + 100])
                      pdf.addImage(pageData, 'jpeg', 25, 20, imgWidth, imgHeight)

                      pdf.setFont('simhei')
                      pdf.save(
                        `${arrList?.name}${arrList?.quarter}公益性收支明细-${moment
                          .utc(new Date())
                          .format('YYYY-MM-DD')}.pdf`,
                      )
                    }
                  })
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              PDF导出
            </Button>
            <Button
              style={{ padding: '2px 10px', marginLeft: 0, marginBottom: 10 }}
              onClick={() => {
                if (arrList !== '') {
                  exportExcel(
                    arrExportList,
                    `${arrList?.name}${arrList?.quarter}公益性收支明细-数据-${moment
                      .utc(new Date())
                      .format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              数据导出
            </Button>
            <Button
              style={{ padding: '2px 10px', marginLeft: 0 }}
              onClick={() => {
                if (arrList !== '') {
                  tabExportExcel(
                    document.getElementById('pdfTable'),
                    `${arrList?.name}${arrList?.quarter}公益性收支明细-表格-${moment
                      .utc(new Date())
                      .format('YYYY-MM-DD')}.xlsx`,
                  )
                }
              }}
              variant="contained"
              className={classes.btn}
            >
              列表导出
            </Button>
          </Collapse>
        </div>
      </div>
      {loadingBoo ? (
        <div className={classes.tableBox}>
          <LoadingBox />
        </div>
      ) : (
        <div className={classes.tableBox}>
          {tableNum === '113' ? <TwoTableBox arrList={arrList} /> : <OneTableBox arrList={arrList} />}
        </div>
      )}
    </div>
  )
}

export default PublicWelfareBox
