import React, { useState } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import DeckGlIconBox from '@/components/baseMap/deckGlIconBox'
import DeckGlScenegraphBox from '@/components/baseMap/deckGlScenegraphBox'
import DeckGlGeoJSONBox from '@/components/baseMap/deckGlGeoJSONBox'
// import DeckGlHeatmapBox from '@/components/baseMap/deckGlHeatmapBox'
import { makeStyles } from 'tss-react/mui'
import Modal from '@mui/material/Modal'
import CanvasBox from '@/components/baseMap/deckGlHeatmapBox/canvasBox'
import FloorPng from '@/components/baseMap/deckGlHeatmapBox/canvasBox/floor.png'
import CanvasBuildBox from '@/components/baseMap/deckGlGeoJSONBox/canvasBuildBox'
// import PlayerBox from '@/components/baseMap/monitorModalBox'
// import DeckGlArcLayerBox from '@/components/baseMap/deckGlArcLayerBox'

const useStyles = makeStyles()(() => ({
  modalBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalSmallBox: {
    width: '50%',
    height: '85%',
    background: '#eee',
    backgroundImage: `url(${FloorPng})`,
    backgroundSize: '100% 100%',
    borderRadius: '0 66% 0 0',
  },
  monitorSmallBox: {
    width: '50%',
    height: '85%',
    background: '#eee',
  },
  modalSmallBoxBuild: {
    borderRadius: 5,
    width: '50%',
    height: '85%',
    background: '#fff',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))

interface BaseMapBoxProps {
  allArr: any
  configJson: any
  map: any
  projectsObj: any
  id: any
}

let layerArr: any[] = []
const DeckGlLayerBox = (props: BaseMapBoxProps) => {
  const { allArr, configJson, map, id } = props

  const { classes } = useStyles()
  const [open, setOpen] = useState<any>(false)
  const [monitorOpen, setMonitorOpen] = useState<any>(false)
  const [openBuild, setOpenBuild] = useState<any>(false)
  const [heatObj, setHeatObj] = useState<any>('')
  const [buildObj, setBuildObj] = useState<any>('')
  const [monitorObj, setMonitorObj] = useState<any>('')

  const recombineArr = (arr: any) => {
    const newArr: any[] = []
    allArr?.deckAllArr?.map((items: any) => {
      const itemArr = arr?.filter((item: any) => item.id.split('-')[0].toString() === items)
      newArr.push(...itemArr)
    })
    return newArr
  }

  const handleClose = () => {
    setOpen(false)
    setHeatObj('')
  }

  const handleMonitorClose = () => {
    setMonitorOpen(false)
    setMonitorObj('')
  }

  const handleCloseBuild = () => {
    setOpenBuild(false)
    setBuildObj('')
  }

  const getLayerArr = (arr: any, allArrS: any) => {
    const newArr = layerArr
    newArr.push(...arr)
    layerArr = newArr
    if (newArr?.length === allArrS?.deckAllArr?.length) {
      map?.deck?.setProps({
        layers: recombineArr(newArr),
        getTooltip: (e: any) => {
          return (
            e.object &&
            (e.object?.properties?.name || `${e.object?.properties?.levels}楼${e.object?.properties?.temperature}°`)
          )
        },
        onClick: (e: any) => {
          if (e?.layer?.id.includes('heatIcon-deckGlIcon')) {
            setHeatObj(e?.object?.properties)
            setOpen(true)
          }
          if (e?.layer?.id.includes('monitorIcon-deckGlIcon')) {
            setMonitorObj(e?.object?.properties)
            setMonitorOpen(true)
          }
          if (e?.layer?.id.includes('building-deckGlGeoJSON')) {
            setOpenBuild(true)
            setBuildObj(e?.object?.properties)
          }
        },
      })
      layerArr = []
    }
  }

  return (
    <>
      <DeckGlIconBox
        getLayerArr={getLayerArr}
        id={id}
        configJson={configJson}
        arr={allArr?.deckGlIconArr}
        map={map}
        allArr={allArr}
      />
      <DeckGlScenegraphBox
        getLayerArr={getLayerArr}
        id={id}
        configJson={configJson}
        arr={allArr?.deckGlScenegraphArr}
        map={map}
        allArr={allArr}
      />
      <DeckGlGeoJSONBox
        getLayerArr={getLayerArr}
        id={id}
        configJson={configJson}
        arr={allArr?.mapBoxGeoJSONArr}
        map={map}
        allArr={allArr}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open && heatObj !== ''}
        onClose={handleClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div id="canvasBox" className={classes.modalSmallBox}>
          <CanvasBox heatObj={heatObj} map={map} idNum={id} configJson={configJson} />
        </div>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={monitorOpen && monitorObj !== ''}
        onClose={handleMonitorClose}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div id="canvasBox" className={classes.monitorSmallBox}>
          {/*<PlayerBox />*/}
        </div>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBuild && buildObj !== ''}
        onClose={handleCloseBuild}
        closeAfterTransition
        className={classes.modalBox}
      >
        <div id="canvasBoxBuild" className={classes.modalSmallBoxBuild}>
          <CanvasBuildBox buildObj={buildObj} map={map} idNum={id} configJson={configJson} />
        </div>
      </Modal>
      {/*<DeckGlHeatmapBox*/}
      {/*  getLayerArr={getLayerArr}*/}
      {/*  id={id}*/}
      {/*  configJson={configJson}*/}
      {/*  arr={allArr?.deckGlHeatMapArr}*/}
      {/*  map={map}*/}
      {/*  allArr={allArr}*/}
      {/*/>*/}

      {/*<DeckGlArcLayerBox*/}
      {/*  getLayerArr={getLayerArr}*/}
      {/*  id={id}*/}
      {/*  configJson={configJson}*/}
      {/*  arr={allArr?.deckGlIconArr}*/}
      {/*  map={map}*/}
      {/*  allArr={allArr}*/}
      {/*/>*/}
    </>
  )
}

export default DeckGlLayerBox
