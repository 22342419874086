import React from 'react'
import { makeStyles } from 'tss-react/mui'
import CircularProgress from '@mui/material/CircularProgress'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  placeholder: {
    height: 40,
  },
}))

const LoadingBox = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.placeholder}>
        <CircularProgress />
      </div>
    </div>
  )
}

export default LoadingBox
