import React, { useEffect, useState } from 'react'
import { getArrList, getPoiList, getViewPort } from '@/api'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  canvasBox: {
    borderRadius: '0 66% 0 0',
  },
}))

interface CanvasBoxProps {
  map: any
  idNum: any
  configJson: any
  heatObj: any
}
const CanvasBox = (props: CanvasBoxProps) => {
  const { map, configJson, idNum, heatObj } = props
  const { classes } = useStyles()
  const [canvasHeight, setCanvasHeight] = useState<any>(0)
  const [canvasWidth, setCanvasWidth] = useState<any>(0)

  // 热力数据
  const [dataList, setDataList] = useState<any>([])

  const randomNum = (m: any, n: any) => {
    return Math.floor(Math.random() * (m - n) + n)
  }

  // 模拟数据
  const getList = () => {
    const arr: any[] = []
    for (let num = 35; num < canvasHeight; num += 35) {
      for (let num1 = 35; num1 < canvasWidth; num1 += 35) {
        arr.push({ x: num1, y: num, value: randomNum(1, 41) })
      }
      if (num + 35 >= canvasHeight) {
        setDataList(arr)
      }
    }
  }

  // 绘制辐射圆
  const drawRadiation = (context: any, opts: any) => {
    // eslint-disable-next-line prefer-const
    let { x, y, radius, weight } = opts
    radius = Math.round(radius * weight)

    // 设置填充色
    const rGradient = context.createRadialGradient(x, y, 0, x, y, radius)
    rGradient.addColorStop(0, 'rgba(0, 0, 0, 1)')
    rGradient.addColorStop(1, 'rgba(0, 0, 0, 0)')
    context.fillStyle = rGradient

    // 设置globalAlpha
    context.globalAlpha = weight

    // 绘制圆形
    context.beginPath()
    context.arc(x, y, radius, 0, 2 * Math.PI)
    context.closePath()

    // 填充
    context.fill()
  }

  // 创建调色盘
  const createPalette = () => {
    const colorStops = {
      0: '#0ff',
      0.2: '#0f0',
      0.4: '#ff0',
      1: '#f00',
    }
    const width = 256,
      height = 20

    // 创建canvas
    const paletteCanvas = document.createElement('canvas')
    paletteCanvas.width = width
    paletteCanvas.height = height
    const ctx: any = paletteCanvas.getContext('2d')

    // 创建线性渐变色
    const linearGradient = ctx.createLinearGradient(0, 0, width, 0)
    for (const key in colorStops) {
      // @ts-ignore
      linearGradient.addColorStop(key, colorStops[key])
    }

    // 绘制渐变色条
    ctx.fillStyle = linearGradient
    ctx.fillRect(0, 0, width, height)

    // 读取像素值
    const imageData = ctx.getImageData(0, 0, width, 1).data

    return {
      canvas: paletteCanvas,
      pickColor: (position: any) => {
        return imageData.slice(position * 4, position * 4 + 3)
      },
    }
  }

  const getCanvas = () => {
    // 0. 创建Canvas并获取上下文
    const canvas: any = document.getElementById('heatMapMain')
    const context = canvas?.getContext('2d')
    context.clearRect(0, 0, canvasWidth, canvasHeight)

    // 1.2.3. 根据热力值绘制辐射圆
    const min = 0
    const max = dataList.reduce((max: any, data: any) => {
      return Math.max(max, data.value)
    }, 0)
    dataList.forEach((data: any) =>
      drawRadiation(context, {
        x: data.x,
        y: data.y,
        radius: 80,
        weight: Math.max(Math.min((data.value - min) / (max - min), 1), 0),
      }),
    )

    // 4. 颜色映射
    const palette = createPalette()
    // document.body.appendChild(palette.canvas);

    const { width, height } = canvas
    const imageData = context.getImageData(0, 0, width, height)
    const data = imageData.data
    for (let i = 3; i < data.length; i += 4) {
      const alpha = data[i]
      const color = palette.pickColor(alpha)
      data[i - 3] = color[0]
      data[i - 2] = color[1]
      data[i - 1] = color[2]
    }
    context.putImageData(imageData, 0, 0)
  }

  useEffect(() => {
    const golfCanvas = document.querySelector('#canvasBox')
    if (golfCanvas?.clientWidth && golfCanvas?.clientHeight) {
      setCanvasWidth(golfCanvas?.clientWidth)
      setCanvasHeight(golfCanvas?.clientHeight)
    }

    getPoiList('deckGlIcon', 'heatIcon', getViewPort(map?.deck?.viewManager?._viewports[0]?.getBounds()))
      ?.then((success: any) => {
        const newArr: any[] = []
        const newDetailArr: any[] = []
        getArrList(success, ['heatIcon'], configJson, idNum)
          ?.features?.filter((item: any) => item.properties?.levels === heatObj?.levels)
          ?.map((items: any) => {
            newDetailArr.push(items?.properties)
            newArr.push(items?.geometry?.coordinates)
          })
        console.log(newArr, newDetailArr)
      })
      .catch((error) => console.error(error))
  }, [])

  useEffect(() => {
    if (canvasWidth !== 0 && canvasHeight !== 0) {
      getList()
    }
  }, [canvasWidth, canvasHeight])

  useEffect(() => {
    if (dataList?.length > 0) {
      getCanvas()
    }
  }, [dataList])

  return <canvas className={classes.canvasBox} width={canvasWidth} height={canvasHeight} id="heatMapMain" />
}

export default CanvasBox
