import React, { useEffect } from 'react'
import { getArrList, getPoiList, getViewPort } from '@/api'

interface BuildingBoxProps {
  map: any
  arr: any
  deckMap?: any
  configJson: any
  id: any
}

let timer: NodeJS.Timeout
function MapBoxGeoJSONBox(props: BuildingBoxProps) {
  const { map, arr, configJson, deckMap, id } = props

  const getGeoJSON = () => {
    let newLayer = map?.getStyle().layers
    newLayer?.splice(0, 134)
    newLayer = newLayer.filter((res: any) => res.id.indexOf('mapBoxGeoJSON') !== -1)
    newLayer?.map((item: any) => {
      if (arr?.mapBoxAllArr.indexOf(item?.id.split('-')[0]) === -1) {
        map.getMap().removeLayer(item.id)
        map.getMap().removeSource(item.source)
      }
    })
    arr?.mapBoxGeoJSONArr?.map((res: any) => {
      getPoiList(
        'mapBoxGeoJSON',
        res.toString(),
        getViewPort(
          deckMap?.deck?.viewManager?._viewports[0]?.getBounds() || [
            map?.getBounds()?._sw?.lng,
            map?.getBounds()?._sw?.lat,
            map?.getBounds()?._ne?.lng,
            map?.getBounds()?._ne?.lat,
          ],
        ),
      )
        .then((success: any) => {
          // @ts-ignore
          if (map.getSource(`${res.toString()}-mapBoxGeoJSON-source`)) {
            map.getSource(`${res.toString()}-mapBoxGeoJSON-source`).setData(getArrList(success, res, configJson, id))
          } else {
            map.getMap().addSource(`${res.toString()}-mapBoxGeoJSON-source`, {
              type: 'geojson',
              data: getArrList(success, res, configJson, id),
            })
            map.getMap().addLayer({
              id: `${res.toString()}-mapBoxGeoJSON-layer`,
              type: 'fill-extrusion',
              source: `${res.toString()}-mapBoxGeoJSON-source`,
              paint: {
                'fill-extrusion-color': ['get', 'color'],
                'fill-extrusion-height': ['get', 'height'],
                'fill-extrusion-base': ['get', 'baseHeight'],
                'fill-extrusion-opacity': 0.4,
              },
            })
            if (
              map.getLayer(
                `${arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]}-${
                  configJson[id][arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]]?.renderType
                }-layer`,
              )
            ) {
              map
                .getMap()
                .moveLayer(
                  `${res.toString()}-mapBoxGeoJSON-layer`,
                  `${arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]}-${
                    configJson[id][arr?.mapBoxAllArr[arr?.mapBoxAllArr.indexOf(res.toString()) + 1]]?.renderType
                  }-layer`,
                )
              // console.log(map?.getStyle().layers)
            }
          }
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    if (map) {
      getGeoJSON()
      clearInterval(timer)
      timer = setInterval(() => {
        getGeoJSON()
      }, 3000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [map, arr])

  return <></>
}

export default MapBoxGeoJSONBox
