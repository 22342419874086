import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'

import BaseMap from '@/components/baseMap'
import AdminBaseMap from '@/components/adminBaseMap'
import { getConfigJson } from '@/api'
import { config } from '@/Constants'
import App from '@/App'
import AdminBox from '@/components/adminBox'
import AuditListBox from '@/components/auditListBox'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const configJson = getConfigJson(config.url.SETTING_URL)
const urlSetting = getConfigJson(config.url.URL_SETTING_URL)
const parkingJson = getConfigJson(config.url.PARKING_URL)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App configJson={configJson} />,
  },
  {
    path: '/:id',
    element: <BaseMap configJson={configJson} urlSetting={urlSetting} />,
  },
  {
    path: '/admin',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/management',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/security',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/admin/:urlId',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/management/:urlId',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/security/:urlId',
    element: <AdminBox parkingJson={parkingJson} configJson={configJson} />,
  },
  {
    path: '/auditList',
    element: <AuditListBox />,
  },
  {
    path: '/admin/map/:id',
    element: <AdminBaseMap configJson={configJson} />,
  },
])

root.render(
  <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
    <RouterProvider router={router} />
  </div>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
