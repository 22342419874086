import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { getAuditList, getParkingLot, getParkingLotList, getQueryParamsObj, setAudits } from '@/api'
import moment from 'moment/moment'
import { FormControl, MenuItem, Select } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ContentBox from '@/components/auditListBox/contentBox'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import NavigationImg from '@/image/navigationImg.png'

const useStyles = makeStyles()(() => ({
  bigRoot: {
    position: 'relative',
    color: '#000',
  },
  alertBox: {
    width: '40vw',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '45%',
    left: 'calc(50% - 20vw)',
  },
  root: {
    width: '100%',
    overflow: 'auto',
    padding: 10,
    '&>div:last-child': {
      margin: 0,
    },
  },
  btnBox: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  rootBg: {
    width: '100%',
    height: '5vh',
    background: '#15283c',
    backgroundImage: `url(${NavigationImg})`,
    color: '#fff',
    fontSize: '3vh',
    boxShadow: '0px 4px 13px -5px #000',
    zIndex: 1,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '3vh',
    paddingBottom: 10,
    '&>div:nth-of-type(1)': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  iconBox: {
    margin: '0 5px',
    width: '3.5vh',
    height: '3.5vh',
  },
  rootBgText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  content: {
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    '&>div:nth-of-type(2)': {
      padding: 10,
      '&>div:last-child': {
        margin: 0,
      },
    },
  },
  content1: {
    marginBottom: 15,
    borderRadius: 5,
    boxShadow: '-1px 1px 15px -4px #000',
    fontSize: '1.5vh',
    padding: 10,
  },
  contentSmallBox: {
    display: 'flex',
    marginBottom: 5,
    '&>div': {
      display: 'flex',
      width: '50%',
      '&>div:nth-of-type(1)': {
        width: '50%',
      },
    },
  },
  remarkBox: {
    display: 'flex',
    marginBottom: 5,
    '&>div:nth-of-type(1)': {
      width: '25%',
    },
    '&>div:nth-of-type(2)': {
      width: '75%',
    },
  },
  contentBox: {
    fontSize: '2vh',
  },
  bigTitle: {
    fontSize: '3.5vh',
    '&>div:nth-of-type(2)': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 10px',
    },
  },
  cardTitle: {
    fontSize: '2.5vh',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#15283c',
    color: '#fff',
    boxShadow: '0px 4px 13px -5px #000',
    padding: 10,
    borderRadius: '5px 5px 0 0',
    backgroundImage: `url(${NavigationImg})`,
    '&>div:nth-of-type(2)': {
      fontSize: '2vh',
      display: 'flex',
    },
  },
  tableBody: {
    border: '1px solid #000',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableBody1: {
    border: '1px solid #000',
    borderTop: 0,
    padding: 10,
    '&>div': {
      height: '4vh',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkBigBox: {
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  checkBox: {
    padding: 0,
    marginRight: 10,
  },
  btn: {
    background: '#214162',
    color: '#fff',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      background: '#214162',
      color: '#fff',
    },
  },
  btn1: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    fontSize: '2.5vh',
    padding: '2px 30px',
    height: '100%',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
  thBox: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '34px',
  },
  thBox1: {
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectBox: {
    fontSize: '2.5vh',
    paddingBottom: 10,
    display: 'flex',
    '&>div:nth-of-type(2)': {
      width: '45%',
    },
  },
  iptModal: {
    height: '100%',
    '&>div': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '1.5vh',
      background: 'transparent',
      padding: '6px 10px',
      height: '100%',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
      '&>div': {
        padding: 0,
      },
    },
    '&>input': {
      color: '#000',
      border: '2px solid #000',
      fontSize: '2vh',
      background: 'transparent',
      padding: '6px 10px',
      '&>input': {
        padding: 0,
      },
      '&>fieldset': {
        border: 0,
        height: '100%',
        top: 0,
      },
    },
    '&:hover::before': {
      borderBottom: '2px solid #000 !important',
    },
  },
  liBox: {
    minHeight: '36px !important',
  },
}))

let timer: NodeJS.Timeout
const AuditListBox = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [iconBtnBoo, setIconBtnBoo] = useState(false)
  const [iptType, setIptType] = useState(false)
  const [parkingLotId, setParkingLotId] = useState<any>('')
  const [aisleValue, setAisleValue] = useState<any>('全部')
  const [allArrList, setAllArrList] = useState<any>([])
  const [arrAisleList, setArrAisleList] = useState<any>([])
  const [billAmountNum, setBillAmountNum] = useState<any>(0)
  const [actualAmountNum, setActualAmountNum] = useState<any>(0)
  const [parkingObj, setParkingObj] = useState<any>({})
  const [disabledValue, setDisabledValue] = useState(true)
  const [alertType, setAlertType] = useState(false)
  const [alertBoo, setAlertBoo] = useState(false)
  const urlName = window.location.pathname
  const dateObj = getQueryParamsObj(window.location?.search)

  useEffect(() => {
    if (urlName.includes('auditList')) {
      navigate(
        `/security/3?id=${dateObj?.id || 101}&date=${
          dateObj?.date || moment.utc(new Date(new Date().getTime() - 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
        }`,
      )
    }
  }, [])

  const getIptBoo = () => {
    setIptType(false)
  }

  const getParkingLotArr = () => {
    getParkingLotList()
      .then((res: any) => {
        setParkingLotId(res?.filter((res: any) => res.projectId === dateObj?.id)[0]?.id || '')
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (dateObj?.id) {
      getParkingLotArr()
    }
  }, [dateObj?.id])

  const getAuditArr = () => {
    setAllArrList([])
    setActualAmountNum(0)
    setBillAmountNum(0)
    getParkingLot(parkingLotId)
      .then((res: any) => {
        document.title = `${res.name}${dateObj?.date}的账单`
        setParkingObj(res)
      })
      .catch(console.error)
    getAuditList(dateObj?.id, dateObj?.date)
      .then((res: any) => {
        const aisleArr: any[] = ['全部']
        res?.map((item: any) => {
          if (item?.gateName !== null) {
            aisleArr.push(item?.gateName === '' ? '未收费岗亭' : item?.gateName)
          }
        })
        setArrAisleList(Array.from(new Set(aisleArr)))
        setAllArrList(res)
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (dateObj?.date === moment.utc(new Date(moment.utc(new Date()).unix() * 1000)).format('YYYY-MM-DD')) {
      setIconBtnBoo(false)
    } else {
      setIconBtnBoo(true)
    }
    if (!dateObj?.date) {
      navigate(
        `${urlName}?id=${dateObj?.id || 101}&date=${moment
          .utc(new Date(new Date().getTime() - 24 * 60 * 60 * 1000))
          .format('YYYY-MM-DD')}`,
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (parkingLotId !== '' && dateObj?.date && dateObj?.id) {
      getAuditArr()
    }
  }, [parkingLotId, dateObj?.date, dateObj?.id])

  const setNewArrList = (arr: any) => {
    setAllArrList([...arr])
  }

  const getDisabledValue = () => {
    disabledValue && setDisabledValue(false)
  }

  const getArr = (arr: any) => {
    let num: any
    if (aisleValue === '全部') {
      num = arr?.length
    } else {
      num = arr?.filter((res: any) => res.gateName === (aisleValue === '未收费岗亭' ? '' : aisleValue))?.length
    }
    return num
  }

  const getUndoneArr = (arr: any) => {
    let num: any
    if (aisleValue === '全部') {
      num = arr?.filter((res: any) => res.remark !== '')?.length
    } else {
      num = arr?.filter(
        (res: any) => res.gateName === (aisleValue === '未收费岗亭' ? '' : aisleValue) && res.remark !== '',
      )?.length
    }
    return num
  }

  const getNumberArr = (arr: any) => {
    const newArr: any = []
    if (aisleValue === '全部') {
      newArr.push(...arr)
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      newArr.push(...arr?.filter((res: any) => res.gateName === (aisleValue === '未收费岗亭' ? '' : aisleValue)))
    }

    return newArr
  }

  useEffect(() => {
    if (allArrList?.length > 0) {
      let num1 = 0
      let num2 = 0
      getNumberArr(allArrList)?.map((item: any) => {
        num1 = num1 + item.actualAmount
        num2 = num2 + item.billAmount
      })
      setActualAmountNum(num1)
      setBillAmountNum(num2)
    }
  }, [aisleValue, allArrList])

  return (
    <div className={classes.bigRoot}>
      {!urlName.includes('management') && !urlName.includes('security') && (
        <div className={classes.rootBg}>
          <div className={classes.rootBgText}>{parkingObj?.name}</div>
        </div>
      )}
      <div
        style={{ height: urlName?.includes('management') || urlName?.includes('security') ? '92vh' : '95vh' }}
        className={classes.root}
      >
        <div className={classes.timeBox}>
          <div>
            <ArrowBackIosNewIcon
              onClick={() => {
                if (!iconBtnBoo) {
                  setIconBtnBoo(true)
                }
                if (!iptType) {
                  setIptType(true)
                }
                aisleValue !== '全部' && setAisleValue('全部')
                navigate(
                  `${urlName}?id=${dateObj?.id || 101}&date=${moment
                    .utc(new Date(moment.utc(dateObj?.date).unix() * 1000 - 24 * 60 * 60 * 1000))
                    .format('YYYY-MM-DD')}`,
                )
                !disabledValue && setDisabledValue(true)
              }}
              className={classes.iconBox}
            />
            {dateObj?.date}
            {iconBtnBoo && (
              <ArrowForwardIosIcon
                onClick={() => {
                  if (!iptType) {
                    setIptType(true)
                  }
                  if (
                    dateObj?.date ===
                    moment
                      .utc(new Date(moment.utc(new Date()).unix() * 1000 - 24 * 60 * 60 * 1000))
                      .format('YYYY-MM-DD')
                  ) {
                    iconBtnBoo && setIconBtnBoo(false)
                  }
                  aisleValue !== '全部' && setAisleValue('全部')
                  navigate(
                    `${urlName}?id=${dateObj?.id || 101}&date=${moment
                      .utc(new Date(moment.utc(dateObj?.date).unix() * 1000 + 24 * 60 * 60 * 1000))
                      .format('YYYY-MM-DD')}`,
                  )
                  !disabledValue && setDisabledValue(true)
                }}
                className={classes.iconBox}
              />
            )}
          </div>
        </div>
        {arrAisleList?.length > 1 && (
          <div className={classes.selectBox}>
            <div>类别：</div>
            <FormControl className={classes.iptModal} fullWidth>
              <Select
                labelId="demo-simple-select-label"
                value={aisleValue}
                onChange={(event: any) => {
                  setAisleValue(event.target.value)
                  !disabledValue && setDisabledValue(true)
                }}
              >
                {arrAisleList?.map((item: any, i: number) => {
                  return (
                    <MenuItem className={classes.liBox} key={i} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
        )}
        <div className={classes.content}>
          <div className={classes.bigTitle}>
            <div className={classes.cardTitle}>
              <div />
              <div>
                <div>完成度：</div>
                <div>{`${getUndoneArr(allArrList)}/${getArr(allArrList)}`}</div>
              </div>
            </div>
            <div>
              <div>应收/实收：</div>
              <div>{`${billAmountNum}/${actualAmountNum}元`}</div>
            </div>
          </div>
          {allArrList?.length > 0 && (
            <div>
              {allArrList?.map((item: any, i: any) => {
                return aisleValue === '全部' ? (
                  <ContentBox
                    iptType={iptType}
                    getIptBoo={getIptBoo}
                    key={i}
                    numId={i}
                    item={item}
                    arrList={allArrList}
                    setNewArrList={setNewArrList}
                    getDisabledValue={getDisabledValue}
                  />
                ) : (
                  item.gateName === (aisleValue === '未收费岗亭' ? '' : aisleValue) && (
                    <ContentBox
                      iptType={iptType}
                      getIptBoo={getIptBoo}
                      key={i}
                      numId={i}
                      item={item}
                      arrList={allArrList}
                      setNewArrList={setNewArrList}
                      getDisabledValue={getDisabledValue}
                    />
                  )
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div className={classes.btnBox}>
        <Button
          onClick={() => {
            if (!disabledValue) {
              setAudits(parkingObj?.id, {
                // audits: [...arrAuditList, ...arrFreeList, ...arrLiftThePoleList],
                audits: [...allArrList],
              })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .then(() => {
                  setDisabledValue(true)
                  setAlertType(true)
                  setAlertBoo(true)
                  timer = setTimeout(() => {
                    setAlertBoo(false)
                  }, 1000)
                  getAuditArr()
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch(() => {
                  setAlertType(false)
                  setAlertBoo(true)
                  timer = setTimeout(() => {
                    setAlertBoo(false)
                  }, 1000)
                })
            }
          }}
          variant="contained"
          className={disabledValue ? classes.btn1 : classes.btn}
        >
          保存
        </Button>
      </div>
      {alertBoo && (
        <Alert className={classes.alertBox} severity={alertType ? 'success' : 'error'}>
          {alertType ? '修改完成！' : '修改失败！'}
        </Alert>
      )}
    </div>
  )
}

export default AuditListBox
