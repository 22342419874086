import React, { useEffect } from 'react'
import { getArrList, getPoiList, getViewPort } from '@/api'
import { GeoJsonLayer } from '@deck.gl/layers/typed'

interface BuildingBoxProps {
  map: any
  arr: any
  configJson: any
  getLayerArr: any
  allArr: any
  id: any
}

let timer: NodeJS.Timeout
function DeckGlGeoJSONBox(props: BuildingBoxProps) {
  const { map, arr, configJson, getLayerArr, allArr, id } = props

  const getDeckGlGeoJSON = () => {
    const newLayerArr: any = []
    arr?.map((res: any) => {
      getPoiList('mapBoxGeoJSON', res.toString(), getViewPort(map?.deck?.viewManager?._viewports[0]?.getBounds()))
        ?.then((success: any) => {
          const layer = new GeoJsonLayer({
            id: `${res.toString()}-deckGlGeoJSON-layer`,
            data: getArrList(success, res, configJson, id)?.features,
            stroked: false,
            filled: true,
            extruded: true,
            wireframe: true,
            getElevation: 0,
            // @ts-ignore
            getFillColor: (f: any) => [...f.properties.color, 100] || [255, 255, 255, 100],
            getLineColor: [255, 255, 255, 200],
            // onClick: (res) => console.log(res),
            pickable: true,
          })
          newLayerArr.push(layer)
          if (newLayerArr?.length === arr?.length) {
            getLayerArr(newLayerArr, allArr)
          }
        })
        .catch((error) => console.error(error))
    })
  }

  useEffect(() => {
    if (map) {
      getDeckGlGeoJSON()
      clearInterval(timer)
      timer = setInterval(() => {
        getDeckGlGeoJSON()
      }, 3000)
    }
    return () => {
      clearInterval(timer)
    }
  }, [map, arr, allArr])

  return <></>
}

export default DeckGlGeoJSONBox
