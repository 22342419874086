import React, { useEffect, useState } from 'react'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import BaseMapBox from '@/components/baseMap/baseMapBox'
import { useParams } from 'react-router-dom'
import { getProjectObj, getQueryParams } from '@/api'
import ModalBox from '@/components/baseMap/modalBox'
// import mqtt from 'mqtt'

interface BaseMapProps {
  configJson: any
  urlSetting: any
}

// let timer: NodeJS.Timeout
const BaseMap = (props: BaseMapProps) => {
  const { configJson, urlSetting } = props
  const { id } = useParams()
  const [projectsObj, setProjectsObj] = useState('')
  const [allArr, setAllArr] = useState<any>({})
  // const [client, setClient] = useState<any>('')

  // useEffect(() => {
  //   const clientId = 'mqtt_react_' + Math.random().toString(16).substring(2, 8)
  //   const username = 'iot'
  //   const password = 'I0t1234'
  //   const clientMqtt = mqtt?.connect('ws://mqtt.hoevo.com:8084/mqtt', {
  //     clientId,
  //     username,
  //     password,
  //     reconnectPeriod: 2000,
  //     connectTimeout: 4000,
  //   })
  //
  //   // clientMqtt?.on('error', () => {
  //   //   clientMqtt?.end()
  //   // })
  //
  //   clientMqtt?.on('connect', () => {
  //     setClient(clientMqtt)
  //   })
  // }, [])

  // useEffect(() => {
  //   if (client) {
  //     client?.subscribe('testChannel/#')
  //     client?.on('message', (topic: any, message: any) => {
  //       const payload = { topic, message: JSON.parse(message) }
  //       console.log(payload)
  //     })
  //   }
  //
  //   return () => {
  //     clearInterval(timer)
  //     if (client) {
  //       client.end()
  //     }
  //   }
  // }, [client])

  const arrObj = () => {
    const mapBoxGeoJSONArr: any = []
    const mapBoxImageArr: any = []
    const mapBoxIconArr: any = []
    const deckGlGeoJSONArr: any = []
    const deckGlImageArr: any = []
    const deckGlIconArr: any = []
    const deckGlScenegraphArr: any = []
    // const deckGlHeatMapArr: any = []
    const mapBoxAllArr: any[] = []
    const deckAllArr: any[] = []
    getQueryParams(window.location?.search)?.source?.map((item: any) => {
      // @ts-ignore
      if (configJson[id]) {
        // @ts-ignore
        switch (configJson[id][item[0]]?.renderType) {
          case 'mapBoxGeoJSON':
            mapBoxGeoJSONArr.push(item)
            mapBoxAllArr.push(item.toString())
            if (item[0] === 'building') {
              deckAllArr.push(item.toString())
            }
            break
          case 'mapBoxImage':
            mapBoxImageArr.push(item)
            mapBoxAllArr.push(item.toString())
            break
          case 'mapboxIcon':
            mapBoxIconArr.push(item)
            mapBoxAllArr.push(item.toString())
            break
          case 'deckGlGeoJSON':
            deckGlGeoJSONArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlImage':
            deckGlImageArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlIcon':
            deckGlIconArr.push(item)
            deckAllArr.push(item.toString())
            break
          case 'deckGlScenegraph':
            deckGlScenegraphArr.push(item)
            deckAllArr.push(item.toString())
            break
          // case 'deckGlHeatMap':
          //   deckGlHeatMapArr.push(item)
          //   deckAllArr.push(item.toString())
          //   break
          default:
            break
        }
      }
    })
    setAllArr({
      mapBoxGeoJSONArr,
      mapBoxImageArr,
      mapBoxIconArr,
      deckGlGeoJSONArr,
      deckGlImageArr,
      deckGlIconArr,
      deckGlScenegraphArr,
      // deckGlHeatMapArr,
      mapBoxAllArr,
      deckAllArr,
    })
  }

  const urlSetArr = (obj: any) => {
    setAllArr(obj)
  }

  useEffect(() => {
    if (id) {
      getProjectObj(id)
        .then((res: any) => {
          document.title = res?.name
          setProjectsObj(res)
        })
        .catch(console.error)
    }
  }, [id])

  useEffect(() => {
    if (id) {
      arrObj()
    }
  }, [id])

  return (
    <>
      <ModalBox urlSetArr={urlSetArr} configJson={configJson} urlSetting={urlSetting} />
      <BaseMapBox id={id} projectsObj={projectsObj} allArr={allArr} configJson={configJson} />
    </>
  )
}

export default BaseMap
