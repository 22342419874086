import React, { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import Button from '@mui/material/Button'
import { getProjectList } from '@/api'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles()(() => ({
  root: {
    width: '80%',
    height: '60vh',
    margin: '20vh 10%',
    overflow: 'auto',
    float: 'left',
  },
  btnBox: {
    width: '20%',
    minWidth: '22vh',
    height: '7.5vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '3vh',
    float: 'left',
  },
  btn: {
    background: '#3582E5',
    minWidth: '22vh',
    minHeight: '7.5vh',
    border: 0,
    color: '#fff',
    fontSize: '3vh',
    padding: 0,
    '&:hover': {
      background: '#3582E5',
      border: 0,
      color: '#fff',
    },
    '&:active': {
      minWidth: '21vh !important',
      minHeight: '6.5vh !important',
      background: '#3582E5 !important',
      fontSize: '2.5vh',
    },
  },
  btnSmallBox: {
    width: '100%',
    maxWidth: '22vh',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:active': {
      maxWidth: '21vh !important',
    },
  },
}))

interface AppProps {
  configJson: any
}

const EditBox = (props: AppProps) => {
  const { configJson } = props
  const { classes } = useStyles()
  const navigate = useNavigate()
  const [projectsArr, setProjectsArr] = useState<any>([])

  useEffect(() => {
    getProjectList()
      .then((res: any) => setProjectsArr(res))
      .catch(console.error)
  }, [])

  const getNavigateUrl = (id: any) => {
    let url: any
    let newUrl: any
    if (configJson[id]) {
      Object.keys(configJson[id])?.map((item) => {
        if (url) {
          url = `${url}&${item}`
        } else {
          url = item
        }
      })
      newUrl = `${id}?${url}`
    } else {
      newUrl = `${id}`
    }
    return newUrl
  }

  return (
    <div className={classes.root}>
      {projectsArr?.map((item: any, i: number) => {
        return (
          <div key={i} className={classes.btnBox}>
            <Button
              disableRipple
              onClick={() => {
                document.title = item?.name
                navigate(`/admin/map/${getNavigateUrl(item.id)}`)
              }}
              variant="contained"
              className={classes.btn}
            >
              <div className={classes.btnSmallBox}>{item?.name}</div>
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default EditBox
